import cx from 'classnames'
import React, { ChangeEvent, createRef, useEffect, useState } from 'react'

import {
  addOrUpdateGuestPlayer,
  switchActiveBlaster,
} from '../../../actions/social/leaderboard-actions'
import { Rank, rankEnum } from '../../../constants/constants'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  selectCurrentGamers,
  selectCurrentTrackSlug,
} from '../../../selectors/current-play-selectors'
import {
  selectMatchStatsByPlayer,
  TrackPlayerCell,
  TrackPlayerRow,
} from '../../../selectors/leaderboard-selectors'
import { selectScoreVersion } from '../../../selectors/session-selectors'
import { GuestPlayer, ScoreCounters } from '../../../types'
import { TextInputWithLabel } from '../../widgets/TextInputWithLabel'
import TrackBadge from '../../widgets/TrackBadge'
import GuestPlayerSetup from '../match-info/GuestPlayerSetup'
import { selectCurrentUsername } from '../../../selectors/session-selectors'

type Props = { matchOwner: string; matchSlug: string; playlistSlug: string }

const StatsByPlayerTable = ({ matchOwner, matchSlug, playlistSlug }: Props) => {
  const dispatch = useAppDispatch()
  const compoundMatchSlug = `${matchOwner}/${matchSlug}`
  const currUsername = useAppSelector(selectCurrentUsername)
  const isOwnedMatch = matchOwner === currUsername
  const isLeaderboardMode = !playlistSlug
  const [workingPlayerOrder, setWorkingPlayerOrder] = useState<string[]>([])
  const trackPlayerData = useAppSelector(
    selectMatchStatsByPlayer(matchOwner, matchSlug, playlistSlug, workingPlayerOrder)
  )
  const {
    colLabels,
    rowLabels,
    dataRows,
    playerCounts: { guestCount },
  } = trackPlayerData

  const currentTrackSlug = useAppSelector(selectCurrentTrackSlug)
  const gamers = useAppSelector(selectCurrentGamers)
  const scoreVersion = useAppSelector(selectScoreVersion)
  const activeGamerIds = gamers.filter(({ isActive }) => isActive).map(({ gamerId }) => gamerId)
  // const initialRowState = rowLabels.map(({ slug }, index) => {
  //   return false
  // })
  const initialCountersState = currentTrackSlug ? { [currentTrackSlug]: true } : {}

  // const [rowState, setRowState] = useState<boolean[]>(initialRowState)
  const [columnCountersState, setColumnCountersState] = useState<{ [key: string]: boolean }>(
    initialCountersState
  )
  const [playlistVizState, setPlaylistVizState] = useState<{ [key: string]: boolean }>({})
  const [indexToSwitchTo, setIndexToSwitchTo] = useState(0)
  const [selectedPlayerInfo, setSelectedPlayerInfo] = useState<GuestPlayer | null>(null)

  useEffect(() => {
    const initialPlaylistState = playlistSlug ? { [playlistSlug]: true } : {}
    setPlaylistVizState(initialPlaylistState)
    setWorkingPlayerOrder([])
  }, [playlistSlug])

  const playerNameEditRef = createRef<HTMLInputElement>()
  React.useEffect(() => {
    const elem = playerNameEditRef.current
    if (elem) {
      elem.select()
    }
  })

  const getCounters = (counters: ScoreCounters) => {
    return rankEnum.map((rank) => {
      const key = `counter-${rank}`
      const rankCount = counters[rank as Rank]
      return (
        <div key={key}>
          <input className={rank} type="text" tabIndex={-1} readOnly value={rankCount} />
        </div>
      )
    })
  }
  const onGuestOrderChange = (newOrder: string[]) => {
    const compoundPlayerSlugs = newOrder.map((playerId) => {
      return `${matchOwner}/${playerId}`
    })
    setWorkingPlayerOrder(compoundPlayerSlugs)
  }
  // const toggleRow = (playlistRow: number) => {
  //   const newState = !rowState[playlistRow]
  //   const newRowState = [...columnState]
  //   newRowState[playlistRow] = newState
  //   for (let row = playlistRow + 1; row < rowLabels.length; row++) {
  //     const { trackInfo } = rowLabels[row]
  //     if (!trackInfo) {
  //       break
  //     }
  //     newRowState[row] = newState
  //   }
  //   setRowState(newRowState)
  // }

  const toggleColumnCounters = (colToToggle: string) => {
    const newState = !!!columnCountersState[colToToggle]
    const newColState = { ...columnCountersState }
    newColState[colToToggle] = newState
    setColumnCountersState(newColState)
  }
  const togglePlaylistViz = (playlistToToggle: string) => {
    const newState =
      playlistToToggle in playlistVizState ? !playlistVizState[playlistToToggle] : true
    const newPlaylistVizState = { ...playlistVizState }
    newPlaylistVizState[playlistToToggle] = newState
    setPlaylistVizState(newPlaylistVizState)
  }
  const getRowLabel = (rowIndex: number) => {
    const { title, slug: compoundPlayerSlug } = rowLabels[rowIndex]
    const [username, playerSlug] = compoundPlayerSlug.split('/')
    const blasterIndex = activeGamerIds.indexOf(playerSlug)
    const isActive = blasterIndex >= 0
    const blastButtonClassname = cx('blastButton', {
      mini: true,
      left: blasterIndex === 0,
      inactive: !isActive,
    })
    const onSwitchToPlayer = () => {
      if (isLeaderboardMode && isOwnedMatch && username === currUsername) {
        setSelectedPlayerInfo({ slug: compoundPlayerSlug, name: title })
        return
      }
      if (isActive) {
        return
      }
      dispatch(switchActiveBlaster({ gamerIndex: indexToSwitchTo, newBlasterSlug: playerSlug }))
      setIndexToSwitchTo((indexToSwitchTo + 1) % activeGamerIds.length)
    }
    const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
      const newName = event.target.value
      if (selectedPlayerInfo) {
        const { name, slug } = selectedPlayerInfo
        if (newName !== name) {
          const isDelete = !newName
          const isRenamingOrDeleteConfirmed = !isDelete || window.confirm(`Delete ${name}?`)
          if (isRenamingOrDeleteConfirmed) {
            if (isDelete && isActive) {
              alert("Can't delete active player")
            } else {
              const player = { slug, name: newName }
              dispatch(addOrUpdateGuestPlayer({ compoundMatchSlug: compoundMatchSlug, player }))
            }
          }
        }
        setSelectedPlayerInfo(null)
      }
    }
    return (
      <td className="sticky-label">
        <div className="label-wrapper">
          <div className="blastButtonWrapper">
            {
              <button className="matchRank" onClick={onSwitchToPlayer} title={compoundPlayerSlug}>
                {rowIndex + 1}
              </button>
            }
            {!isLeaderboardMode && (
              <button className={blastButtonClassname} onClick={onSwitchToPlayer} />
            )}
          </div>
          <div className="playerScore">
            {selectedPlayerInfo?.slug === compoundPlayerSlug ? (
              <TextInputWithLabel
                label={''}
                value={title}
                onBlur={onNameChange}
                onRef={playerNameEditRef}
              />
            ) : (
              <div className={badgeClass} onClick={onSwitchToPlayer}>
                {title}
              </div>
            )}
          </div>
        </div>
      </td>
    )
  }
  const badgeClass = cx('playerBadge', { guestName: guestCount > 0 })
  const getHeader = () => {
    let showTracks = false
    return (
      <tr>
        <th className="sticky-header corner">
          <div className="wrapper">
            <GuestPlayerSetup
              compoundMatchSlug={compoundMatchSlug}
              isAllowNewPlayer={isLeaderboardMode}
              onGuestOrderChange={isLeaderboardMode ? undefined : onGuestOrderChange}
            />
          </div>
        </th>
        {colLabels.map((columnLabel, index) => {
          const isTrack = !!columnLabel.trackInfo
          if (!isTrack) {
            showTracks = playlistVizState[columnLabel.slug]
          } else if (!showTracks) {
            return null
          }

          const className = cx('sticky-header', {
            isMatch: index === colLabels.length - 1,
            isRound: !isTrack,
          })
          return (
            <th
              key={index}
              className={className}
              onClick={() => {
                if (isTrack) {
                  toggleColumnCounters(columnLabel.slug)
                } else {
                  togglePlaylistViz(columnLabel.slug)
                }
              }}
            >
              <TrackBadge statsCell={columnLabel} scoreVersion={scoreVersion} />
            </th>
          )
        })}
      </tr>
    )
  }
  const getBody = () => {
    return dataRows.map((row, rowIndex) => {
      let showTracks = false
      const visibleRow: TrackPlayerRow = []
      row.forEach((column, colIndex) => {
        const isTrack = !!colLabels[colIndex].trackInfo
        if (!isTrack) {
          showTracks = playlistVizState[column.slug]
        } else if (!showTracks) {
          return
        }
        column.trackInfo = colLabels[colIndex].trackInfo
        visibleRow.push(column)
      })
      return (
        <tr key={rowIndex}>
          {getRowLabel(rowIndex)}
          {visibleRow.map(
            (
              { slug, score, scoreRank, counters, trackInfo }: TrackPlayerCell,
              colIndex: number
            ) => {
              const isMatchColumn = colIndex === visibleRow.length - 1
              const isShowCounters = counters && columnCountersState[slug]
              return (
                <td key={colIndex}>
                  <div
                    className={cx('playerScore', {
                      isRound: !trackInfo,
                      isMatch: isMatchColumn,
                    })}
                  >
                    {score > 0 && (
                      <div className={cx('score', { [scoreRank || '']: true })}>{score}</div>
                    )}
                    {isShowCounters && (
                      <div className="counters">{isShowCounters && getCounters(counters)}</div>
                    )}
                  </div>
                </td>
              )
            }
          )}
        </tr>
      )
    })
  }

  return (
    <div className="statsByPlayerTable">
      <div className="table-container">
        <table className="table" cellPadding={0}>
          <thead>{getHeader()}</thead>
          <tbody>{getBody()}</tbody>
        </table>
      </div>
    </div>
  )
}

export default StatsByPlayerTable
