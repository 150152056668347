import React, { ChangeEvent } from 'react'

import loadLocalTiming from '../../actions/authoring/loadLocalTiming'
import { useAppSelector, useAppDispatch } from '../../hooks'
import {
  toggleNoviceAssistEnabled,
  toggleShowSections,
  toggleVizSwitchingEnabled,
  toggleShowSectionAnimation,
} from '../../actions/settings-actions'
import settingsSlice from '../../reducers/settingsSlice'
import { selectIsEditable, selectIsPlayable } from '../../selectors/current-play-selectors'
import { selectViewSettings } from '../../selectors/settings-selectors'
import { selectModeFlags } from '../../selectors/session-selectors'
import { SettingsActions } from '../../types'
import VizSettings from './VizSettings'

type Props = {
  settingsActions: SettingsActions
}
const GameSettings = ({ settingsActions }: Props) => {
  const playerIndex = 0 // TODO
  const { copyReference, toggleTimes, toggleVisualPulse, toggleAudioPulse } = settingsActions
  const { isEditMode, isPlayMode } = useAppSelector(selectModeFlags)
  const dispatch = useAppDispatch()
  const [timingsURLRef, setTimingsURLRef] = React.useState<any>()
  const [isLoadAsReference, setIsLoadAsReference] = React.useState(true)
  const {
    isShowSectionAnimation,
    isShowSections,
    isShowTimes,
    isVisualPulse,
    isAudioPulse,
    isOptionKey,
    isNoviceAssistEnabled,
    isVizSwitchingEnabled,
  } = useAppSelector(selectViewSettings(playerIndex))
  const isEditable = useAppSelector(selectIsEditable)
  const isPlayable = useAppSelector(selectIsPlayable)
  const onCopyRefClick = () => {
    copyReference()
  }
  const onLoadRefClick = () => {
    setIsLoadAsReference(!isOptionKey)
    timingsURLRef.click()
  }
  const onShowTimingToggle = (event: ChangeEvent<HTMLInputElement>) => {
    const isShow = event.target.checked
    toggleTimes(isShow)
    // dispatch(settingsSlice.actions.setIsShowTimes(isShow)) // TODO: clean up the others
  }
  const onVizSwitchingToggle = (event: ChangeEvent<HTMLInputElement>) => {
    const isEnabled = event.target.checked
    dispatch(toggleVizSwitchingEnabled({ playerIndex, isEnabled })) // TODO: move the rest of these to play-actions
  }
  const onNoviceAssistToggle = (event: ChangeEvent<HTMLInputElement>) => {
    const isNoviceAssistEnabled = event.target.checked
    dispatch(toggleNoviceAssistEnabled(isNoviceAssistEnabled)) // TODO: move the rest of these to play-actions
  }
  const onShowSectionsToggle = (event: ChangeEvent<HTMLInputElement>) => {
    const isShow = event.target.checked
    dispatch(toggleShowSections(isShow)) // TODO: move the rest of these to play-actions
  }
  const onAnimateSectionToggle = (event: ChangeEvent<HTMLInputElement>) => {
    const isShowSectionAnimation = event.target.checked
    console.log('check now is ', isShowSectionAnimation)
    dispatch(toggleShowSectionAnimation(isShowSectionAnimation))
  }
  const onVisualPulseToggle = (event: ChangeEvent<HTMLInputElement>) => {
    const isPulse = event.target.checked
    toggleVisualPulse(isPulse)
    dispatch(settingsSlice.actions.setIsVisualPulse({ isVisualPulse: isPulse }))
  }
  const onAudioPulseToggle = (event: ChangeEvent<HTMLInputElement>) => {
    const isPulse = event.target.checked
    toggleAudioPulse(isPulse)
    dispatch(settingsSlice.actions.setIsAudioPulse({ isAudioPulse: isPulse }))
  }
  const onTimingsURLChange = () => {
    const file = timingsURLRef.files[0]
    dispatch(loadLocalTiming({ asReference: isLoadAsReference, file }))
    timingsURLRef.value = ''
  }

  // TODO: disable most buttons if playing
  return (
    <div className="settingsContainer">
      <div className="song-ui">
        <div className="songSettingGroup">
          <div className="songSetting">
            <input
              type="checkbox"
              id="time-toggle"
              checked={isShowTimes}
              onChange={onShowTimingToggle}
            />
            <label htmlFor="time-toggle">Show Timing</label>
          </div>
          <div className="songSetting">
            <input
              type="checkbox"
              id="sectionsToggle"
              checked={isShowSections}
              onChange={onShowSectionsToggle}
            />
            <label htmlFor="sectionsToggle">Show Sections</label>
          </div>
          <div className="songSetting">
            <input
              type="checkbox"
              id="sectionAnimation"
              checked={isShowSectionAnimation}
              onChange={onAnimateSectionToggle}
            />
            <label htmlFor="sectionAnimationToggle">Animated Sections</label>
          </div>
          {!isEditMode && (
            <div className="songSetting">
              <input
                type="checkbox"
                id="vizSwitchingToggle"
                checked={isVizSwitchingEnabled}
                onChange={onVizSwitchingToggle}
              />
              <label htmlFor="vizSwitchingToggle">Animated Breaks</label>
            </div>
          )}
          {isPlayMode && (
            <div className="songSetting">
              <input
                type="checkbox"
                id="noviceAssistToggle"
                checked={isNoviceAssistEnabled}
                onChange={onNoviceAssistToggle}
              />
              <label htmlFor="noviceAssistToggle">Novice Assist</label>
            </div>
          )}
          <div className="songSetting">
            <input
              type="checkbox"
              id="visualPulseToggle"
              checked={isVisualPulse}
              onChange={onVisualPulseToggle}
            />
            <label htmlFor="visualPulseToggle">Visual Pulse</label>
          </div>
          {false && (
            <div className="songSetting">
              <input
                type="checkbox"
                id="audioPulseToggle"
                checked={isAudioPulse}
                onChange={onAudioPulseToggle}
              />
              <label htmlFor="audioPulseToggle">Audio Pulse</label>
            </div>
          )}
          <div className="songSetting">
            <input
              ref={setTimingsURLRef}
              className="hidden"
              type="file"
              tabIndex={-1}
              onChange={onTimingsURLChange}
            />
            {isEditable && (
              <button
                id="copy-reference"
                className="chatNewTrackButton"
                disabled={!isPlayable}
                onClick={onCopyRefClick}
              >
                Copy Ref
              </button>
            )}
            {isEditable && ( // TODO: decide how/when expose Load Score for non-owned
              <button className="chatNewTrackButton" onClick={onLoadRefClick}>
                {isOptionKey ? 'Load Score...' : 'Load Ref...'}
              </button>
            )}
          </div>
        </div>
      </div>
      <VizSettings playerIndex={0} />
    </div>
  )
}

export default GameSettings
