import React from 'react'

import { useAppDispatch, useAppSelector } from '../../hooks'
import socialSlice from '../../reducers/socialSlice'
import { selectUserTracksInfo } from '../../selectors/blaster-peer-selectors'
import { selectIsUserPlaylistExpanded } from '../../selectors/social-selectors'
import { selectScoreVersion } from '../../selectors/session-selectors'
import { MatchInfo, PlaylistInfo, PlayStatus, PlayStatusMap } from '../../types'
import { defaultTrackPlayStatus } from '../../util/score-utils'
import BlastRecord from './BlastRecord'

type Props = {
  username: string
  matchInfo: MatchInfo
  playlistInfo: PlaylistInfo
  playlistScore: PlayStatus
  trackScores: PlayStatusMap
  isScoredTracksOnly: boolean
}

const PlaylistContainer = ({
  username,
  matchInfo,
  playlistInfo,
  playlistScore,
  trackScores = {},
  isScoredTracksOnly,
}: Props) => {
  const dispatch = useAppDispatch()
  const { slug: playlistSlug, trackOrder = [] } = playlistInfo // shouldn't have to default... TODO
  const { slug: matchSlug } = matchInfo
  const matchOwner = matchSlug.split('/')[0]
  const isShowChildren = useAppSelector(
    selectIsUserPlaylistExpanded({ username, matchSlug, playlistSlug })
  )
  const tracks = useAppSelector(selectUserTracksInfo(matchOwner))
  const scoreVersion = useAppSelector(selectScoreVersion)
  const onToggleChildren = () => {
    const isExpanded = !isShowChildren
    dispatch(
      socialSlice.actions.togglePlaylistExpanded({
        isLeft: false,
        username,
        matchSlug,
        playlistSlug,
        isExpanded,
      })
    )
  }
  const getTracks = () => {
    const usableTracks = trackOrder.filter((trackSlug) => {
      if (!(trackSlug in tracks)) {
        return false
      }
      if (isScoredTracksOnly) {
        const playStatus = trackScores[trackSlug]
        return !!playStatus && playStatus.score > 0
      }
      return true
    })
    return usableTracks.map((trackSlug) => {
      const trackInfo = tracks[trackSlug]
      const playStatus = trackScores[trackSlug] || defaultTrackPlayStatus(trackInfo, scoreVersion)

      return (
        <BlastRecord
          key={`${username}-${matchSlug}-${playlistSlug}-${trackSlug}`}
          username={username}
          matchInfo={matchInfo}
          playlistInfo={playlistInfo}
          trackInfo={trackInfo}
          matchStatus={playStatus}
        />
      )
    })
  }
  return (
    <React.Fragment>
      <BlastRecord
        username={username}
        matchInfo={matchInfo}
        playlistInfo={playlistInfo}
        matchStatus={playlistScore}
        onToggleChildren={onToggleChildren}
        isChildrenVisible={isShowChildren}
      />
      {isShowChildren && getTracks()}
    </React.Fragment>
  )
}

export default PlaylistContainer
