import * as Tone from 'tone'

import { cancelEditTarget } from '../actions/authoring/lyric-editing'
import doSave from '../actions/save'
import updateScore from '../actions/updateScore'
import scheduleVizSwitches from '../actions/viz-switch'
import scheduleSectionAnimations from '../actions/section-animation'
import {
  BLAST_ASSIST_DELAY_MILLIS,
  DEFAULT_PREROLL_SECS,
  defaultScoreDelta,
  SECTION_FONT_SIZE_FACTOR,
} from '../constants/constants'
import { GREEDY_LINE_BREAK_REGEX } from '../constants/utility-constants'
import getState, { AppDispatch } from '../reducers'
import currentPlaySlice from '../reducers/currentPlaySlice'
import settingsSlice from '../reducers/settingsSlice'
import { selectCurrentLRC, selectCurrentLyrics } from '../selectors/current-play-selectors'
import { selectVisualizationInfo } from '../selectors/session-selectors'
import { Line, ModelPart, ModeType, Section, Word, Target, ScoreDelta } from '../types'
import doImportLRC, { extractLyrics } from '../util/lyrics/import-lrc'
import {
  getNextLine,
  getNextWord,
  getPartByIndex,
  getPartIndex,
  getPrevWord,
  getPrevLine,
  getTimeForPart,
  timeToWord,
  isZerothPart,
} from '../util/lyrics/lyric-navigation'
import { clearWordScoringClasses, updateWordOnBlast } from '../util/score-utils'
import Util from '../util/util'
import { autoScroll as doAutoScroll } from '../util/scrolling'
import {
  getLyricsWithoutTiming,
  getLyricsWithTiming,
  traverseLyrics,
  traverseLyricsSubset,
} from '../util/lyrics/traverse-lyrics'
import { NullAudioPlayer } from './AudioWrappers'
import { LyricVizBuilder } from './LyricVizBuilder'
import { TrackMixer } from './TrackMixer'

type PartEventArgs = {
  eventCallback: any
  part: ModelPart
  useReferenceTime: boolean
  deltaTime?: number
}
const scoreClearingVisitor = {
  visitTrack: (isStart: boolean) => {},
  visitSection: (section: Section) => {},
  visitLine: (line: Line) => {},
  visitWord: (word: Word) => {
    clearWordScoringClasses(word)
  },
}

class Gamer {
  gamerIndex: number
  _trackMixer: TrackMixer
  isDragging: boolean
  _volume: number
  _isReady: boolean
  _timedWordCount: number
  _pointsPerWord: number
  _lyricFontSize: number
  displayTimes: boolean
  isShowSections: boolean
  isVisualPulseActiveWord: boolean
  isAudioPulseActiveWord: boolean
  isShowLyrics: boolean
  isNoviceAssistEnabled: boolean
  lastBlastTime: number
  target: Target
  firstTarget: Target
  _trackElem: HTMLElement | null
  playHead: Target
  _scoreSeconds: number
  _prevSeconds: number
  latencySeconds: number
  private _scoreClockLeft: HTMLInputElement | null
  private _clock: HTMLInputElement | null
  private _scrubber: HTMLInputElement | null
  private _duration: HTMLInputElement | null
  _audioFileInput: HTMLInputElement | null
  _localAudioFileToUpload: File | undefined
  _imageFileInput: HTMLInputElement | null
  _localImageFileToUpload: File | undefined
  _interactionContainer: HTMLElement | null
  _spellingTarget: HTMLDivElement | null
  vizBuilder: LyricVizBuilder
  synth: any

  constructor(gamerIndex: number, trackMixer: TrackMixer) {
    this.gamerIndex = gamerIndex
    this.isDragging = false
    this._trackMixer = trackMixer
    this._volume = 5.0
    this._isReady = false
    this._timedWordCount = 0
    this._pointsPerWord = 0
    this._lyricFontSize = 12
    // TODO: remove these if possible
    this.displayTimes = false
    this.isShowSections = true
    this.isVisualPulseActiveWord = false
    this.isAudioPulseActiveWord = false
    this.isShowLyrics = true
    this.isNoviceAssistEnabled = false
    this.target = {
      sectionIndex: 0,
      lineIndex: 0,
      wordIndex: -1,
      elem: null,
    }
    this.lastBlastTime = 0
    this.firstTarget = this.target
    this._trackElem = null // track bumper
    this._spellingTarget = null
    this.playHead = this.target
    this._scoreSeconds = 0.0
    this._prevSeconds = 0.0
    this.latencySeconds = 0
    this._audioFileInput = null
    this._imageFileInput = null
    this._interactionContainer = null
    this._spellingTarget = null
    this._scoreClockLeft = null
    this._clock = null
    this._scrubber = null
    this._duration = null
    this.vizBuilder = new LyricVizBuilder(this)
    this._interactionContainer = null
  }

  get interactionContainer(): HTMLElement {
    if (!this._interactionContainer) {
      // console.log(`interactionContainer not set for [${this.gamerIndex}]`)
      throw new Error(`interactionContainer not set for [${this.gamerIndex}]`)
    }
    return this._interactionContainer
  }

  initUI(isTransferTimings = false) {
    this._interactionContainer = document.getElementById(`gameContainer-${this.gamerIndex}`)
    if (this._interactionContainer) {
      this._interactionContainer.scrollTop = 0
      this._interactionContainer.scrollLeft = 0
    }
    this._spellingTarget = document.createElement('div')
    this.spellingTarget.classList.add('word', 'spellingTarget')
    this._updateLyrics(isTransferTimings)
    this.rewindToStart()
    this.dispatch(currentPlaySlice.actions.setIsScoreDirty(false))
    // this.dispatch(currentPlaySlice.actions.setTrackDuration(this.trackMixer.trackDuration))
  }

  switchMode(fromMode: ModeType, referenceLrcLines: string[]) {
    const isSwitchFromPlay = fromMode === 'play'
    const isSwitchToPlay = this._trackMixer.isPlayMode
    const isSwitchToViz = this._trackMixer.isVizMode
    const isSwitchToEdit = this._trackMixer.isEditMode
    const isSwitchToSpell = this._trackMixer.isSpellMode
    this.isVisualPulseActiveWord = isSwitchToViz
    this.isAudioPulseActiveWord = false
    const targetToRestore = this.target
    this.initUI()
    if (referenceLrcLines.length) {
      if (isSwitchToEdit) {
        this.importLRC({ asReference: false, lrcLines: referenceLrcLines, isLoad: true })
      } else {
        this.importLRC({ asReference: true, lrcLines: referenceLrcLines, isLoad: false })
        if (isSwitchToViz && isSwitchFromPlay) {
          const playerLrcLines = this.currentLRC.split(GREEDY_LINE_BREAK_REGEX)
          this.importLRC({ asReference: false, lrcLines: playerLrcLines, isLoad: true })
        }
        if (isSwitchToPlay) {
          this.dispatch(scheduleSectionAnimations(this))
          if (!this.vizBuilder.isVizSwitchingScheduled) {
            this.dispatch(scheduleVizSwitches(this))
          }
        }
      }
    }
    this.toggleTimes(isSwitchToEdit)
    this.toggleMasked(!isSwitchToSpell)
    this._resetTarget(targetToRestore, true)
  }

  get dispatch(): AppDispatch {
    return this._trackMixer.dispatch
  }
  set scoreClockLeft(scoreClock: HTMLInputElement) {
    this._scoreClockLeft = scoreClock
  }
  get hasFocus() {
    return this._interactionContainer?.parentElement?.classList.contains('hasFocus')
  }
  get spellingTarget(): HTMLDivElement {
    if (!this._spellingTarget) {
      throw new Error('interactionContainer not set')
    }
    return this._spellingTarget
  }
  get player(): NullAudioPlayer {
    return this._trackMixer._audioPlayers.default
  }
  // set player(playerKey: string) {
  //   const newPlayer = this._audioPlayers[playerKey]
  //   this._player = newPlayer || this._audioPlayers['default']
  // }
  get volume() {
    return this._volume
  }

  get currentLRC() {
    return getLyricsWithTiming(this.vizBuilder.sections)
  }

  get currentLyricsText() {
    return getLyricsWithoutTiming(this.vizBuilder.sections)
  }

  get userInputAudioFile() {
    return this._localAudioFileToUpload
  }
  set userInputAudioFile(localAudioFile: File | undefined) {
    this._localAudioFileToUpload = localAudioFile
    this._trackMixer.audioFileInput.value = ''
  }

  get userInputImageFile() {
    return this._localImageFileToUpload
  }
  set userInputImageFile(localAudioFile: File | undefined) {
    this._localImageFileToUpload = localAudioFile
    this._trackMixer.imageFileInput.value = ''
  }

  get isReady() {
    return this._isReady
  }

  set isReady(isReady) {
    this._isReady = isReady
  }

  get isPlaying() {
    return this._trackMixer.isPlaying
  }

  get isBlasting() {
    return this._trackMixer.isPlaying && this._trackMixer.isPlayMode
  }

  get timedWordCount() {
    return this._timedWordCount
  }

  set timedWordCount(timedWordCount) {
    this._timedWordCount = timedWordCount
  }
  get pointsPerWord() {
    return this._pointsPerWord
  }
  set pointsPerWord(pointsPerWord: number) {
    this._pointsPerWord = pointsPerWord
  }

  resume() {
    this.isReady = true
  }
  suspend() {
    this.isReady = false
  }

  get scoreSeconds() {
    return this._scoreSeconds
  }
  set scoreSeconds(clockSeconds: number) {
    const { _scoreSeconds, _prevSeconds } = this
    const newScoreSeconds = _scoreSeconds + (clockSeconds - _prevSeconds)
    this._prevSeconds = clockSeconds
    this._scoreSeconds = newScoreSeconds
    if (this._scoreClockLeft) {
      const clockText = Util.secondsToClock(newScoreSeconds)
      this._scoreClockLeft.value = clockText
    }
    // const numLaps = Math.floor(newScoreSeconds / trackDuration);
    // this.dispatch(currentPlaySlice.actions.updateScoreClock({ numLaps, scoreSeconds }));
  }

  get trackElem(): HTMLElement | null {
    return this._trackElem
  }
  set trackElem(elem) {
    this._trackElem = elem
  }

  updateAnimation() {
    this.vizBuilder.tick()
  }

  _updateLyrics(isTransferTimings = false) {
    this.vizBuilder.sections.forEach((section: Section) => {
      const { lines } = section
      this.removePartEvent(section)
      lines.forEach((line: Line) => {
        const { words } = line
        this.removePartEvent(line)
        words.forEach((word) => {
          this.removePartEvent(word)
        })
      })
    })
    const newLyrics = selectCurrentLyrics(getState())
    this.vizBuilder.updateLyrics(isTransferTimings, newLyrics)
    if (!isTransferTimings) {
      this.dispatch(cancelEditTarget()) // TODO: why?
    }
    this.vizBuilder.buildLyrics()
    this.recalc()
  }

  // clear individual words when user goes back
  clearWord(word: Word, shouldRecalc = false) {
    if (this.isPlaying) {
      // this.stop(); // TODO: revisit!
    }
    word.score = undefined
    word.time = null

    // if (this.isReady) {
    if (shouldRecalc) {
      this.recalc()
    }
    this._clearWord(word)
  }

  clearSection(section: Section) {
    section.score = undefined
    section.time = null

    this._clearSection(section)
  }

  clearTiming() {
    const wasDirty = this._trackMixer.isEditMode && this.vizBuilder.timedWordCount > 0
    this._resetSession()
    if (wasDirty) {
      this._updateDirty()
    }
  }

  // clears all words and sets player back to beginning
  _resetSession() {
    // TODO: check if already reset?
    const self = this
    const clearVisitor = {
      visitTrack: (isStart: boolean) => {},
      visitSection: (section: Section) => {
        self.clearSection(section)
      },
      visitLine: (line: Line) => {},
      visitWord: (word: Word) => {
        self.clearWord(word)
      },
    }
    traverseLyrics(this.vizBuilder.sections, clearVisitor)
    this.rewindToStart()
    this.recalc()
    this.dispatch(currentPlaySlice.actions.setIsScoreDirty(false))

    if (this._trackMixer.isEditMode) {
      this.timedWordCount = 0
      this.vizBuilder.timedWordCount = 0
    }
  }

  get targetWord() {
    if (!this.target.elem) {
      return null
    }

    return getPartByIndex(this.target, this.vizBuilder.sections)
  }

  // sets time to either the playHead or target
  rewindToWord(isPlayHead: boolean) {
    let word = null
    if (isPlayHead && this.playHead.elem) {
      word = getPartByIndex(this.playHead, this.vizBuilder.sections)
    } else if (!isPlayHead && this.target.elem) {
      word = this.targetWord
    }
    if (word) {
      const { time, referenceTime } = word
      const wordTime = time || referenceTime || 0
      this._trackMixer.rewindTo(wordTime, word)
    }
  }
  rewindToTargetWithPreroll() {
    const nextWord = this.targetWord
    const time = this._trackMixer.isEditMode ? nextWord?.time : nextWord?.referenceTime
    const rollbackTo = Math.max(0, (time || 0) - DEFAULT_PREROLL_SECS)
    this._trackMixer.rewindTo(rollbackTo)
  }

  releaseBlast(isRight: boolean) {
    this.dispatch(currentPlaySlice.actions.unPress(this.gamerIndex))
  }
  blast(isRight: boolean) {
    if (this._trackMixer.isVizMode) {
      return
    }

    if (!this.isPlaying) {
      this.dispatch(
        currentPlaySlice.actions.setScoreDelta({
          gamerIndex: this.gamerIndex,
          scoreDelta: { ...defaultScoreDelta(), isRight },
        })
      )
      // this.dispatch(currentPlaySlice.actions.play('bottle'))
      return
    }
    const word = this.targetWord
    if (!word) {
      return
    }
    word.time = this._trackMixer.clockSeconds

    if (this._trackMixer.isEditMode) {
      this.timedWordCount += 1
    }
    this.lastBlastTime = Date.now()
    const scoreDelta = updateWordOnBlast(
      word as Word,
      this._trackMixer.isEditMode,
      this.latencySeconds,
      this.pointsPerWord,
      isRight
    )
    this.dispatch(
      currentPlaySlice.actions.setScoreDelta({
        gamerIndex: this.gamerIndex,
        scoreDelta: scoreDelta || defaultScoreDelta(),
      })
    )
    this.vizBuilder.updatePartHTML(word)
    this.recalc(scoreDelta)
    this._loadNextWord()
    this._updateDirty()
  }

  _loadNextWord(isSeek = false) {
    const nextWord = getNextWord(this.target, this.vizBuilder.sections)
    const { elem } = nextWord
    if (elem) {
      this.autoScroll(elem)
      this._resetTarget(nextWord)
      if (isSeek && !this.isBlasting) {
        this.rewindToTargetWithPreroll()
        this.playHead = this.target
      }
    }
    return !!elem
  }

  // jump to first word of next line
  _loadNextLine(isSeek = false) {
    const nextLine = getNextLine(this.target, this.vizBuilder.sections)
    if (nextLine.elem) {
      this.autoScroll(nextLine.elem)
      this._resetTarget(nextLine)

      if (isSeek && !this.isBlasting) {
        this.rewindToTargetWithPreroll()
        this.playHead = this.target
      }
    }
  }

  _loadPrevWord(isPlayHead = false) {
    const target = isPlayHead ? this.playHead : this.target
    const prevWord = getPrevWord(target, this.vizBuilder.sections)
    if (prevWord.elem) {
      this.autoScroll(prevWord.elem)

      if (!isPlayHead) {
        this._resetTarget(prevWord)
      }
      this.playHead = prevWord

      if (!this.isBlasting) {
        this.rewindToTargetWithPreroll()
      }
    }
  }

  // jump to first word of previous line
  _loadPrevLine(isPlayHead = false) {
    const target = isPlayHead ? this.playHead : this.target
    const prevLine = getPrevLine(target, this.vizBuilder.sections)
    if (prevLine.elem) {
      this.autoScroll(prevLine.elem)

      if (!isPlayHead) {
        this._resetTarget(prevLine)
      }
      this.playHead = prevLine
      if (!this.isBlasting) {
        if (isPlayHead) {
          this.rewindToWord(isPlayHead)
        } else {
          this.rewindToTargetWithPreroll()
        }
      }
    }
  }

  _resetTarget(newTarget?: Target, isRefresh = false) {
    if (this._spellingTarget) {
      this._spellingTarget.innerText = ''
    }
    // if (!newTarget) {
    //   return
    // }
    if (this.target && this.target.elem) {
      // TODO: make sure always a target (here and below) so check not necessary?
      this.target.elem.classList.remove('target', 'spelling')
    }
    this.target = newTarget || this.firstTarget

    if (this.target && this.target.elem) {
      this.target.elem.classList.add('target')
      if (this._trackMixer.isVizMode) {
        this.target.elem.classList.remove('masked')
      }
      if (this._spellingTarget) {
        this.target.elem.appendChild(this._spellingTarget)
      }
    }
    if (isRefresh) {
      this.refreshTarget()
    }
  }

  refreshTarget() {
    const part = getPartByIndex(this.target, this.vizBuilder.sections)
    const newTarget = {
      ...this.target,
      elem: part ? part.elem : null,
    }
    this._resetTarget(newTarget)
    this.autoScroll(newTarget.elem)
  }

  _updateDirty() {
    this.dispatch(currentPlaySlice.actions.setIsScoreDirty(true))
  }

  // Audio Manager
  start() {
    const startAt = this._trackMixer.clockSeconds
    if (this._trackMixer.isVizMode && startAt === 0) {
      traverseLyrics(this.vizBuilder.sections, scoreClearingVisitor)
    }
    this.lastBlastTime = 0
    if (this._trackMixer.isPlayMode) {
      this._toggleSelection()
    }
  }

  // Audio Manager
  stop() {
    this.recalc()
    // if (this.isInScheduledVisualization) {
    //   const defaultVizSlug = selectDefaultVisualizationSlug(getState())
    //   this.switchViz({ isSwitchingIn: false, vizSlug: defaultVizSlug }) // TODO: does event get canceled?
    // }
    if (this._trackMixer.isEditMode) {
      this.dispatch(doSave({ gamer: this }))
    } else {
      this.vizBuilder.updateViz() // toggle score highlighting, etc
    }
    if (this.isNoviceAssistEnabled && this._trackMixer.isPlayMode) {
      this.rewindToTargetWithPreroll()
    }
  }

  _resetVizToDefault() {
    const { currentVizSlug, defaultVizSlug } = selectVisualizationInfo(getState())[this.gamerIndex]
    if (currentVizSlug !== defaultVizSlug) {
      this.vizBuilder.switchVisualization(defaultVizSlug)
    }
  }
  // Audio Manager
  rewindToStart() {
    this.interactionContainer.scrollTop = 0
    this.interactionContainer.scrollLeft = 0
    this.scoreSeconds = 0.0
    this._prevSeconds = 0.0
    this._resetVizToDefault()
    this._resetTarget(this.firstTarget, true)
    this._toggleSelection(null)
  }

  // Audio Manager
  _rewindTo(seconds: number, part: ModelPart | null = null) {
    this.scoreSeconds = seconds
    this._prevSeconds = seconds
    if (!part) {
      part = timeToWord(seconds, this.vizBuilder.sections, !this._trackMixer.isEditMode)
    }
    this._resetVizToDefault()
    this._toggleSelection(part)
    // TODO: when blasting or modding, set target to first word in section -- unless target is already within first N words?
  }

  recalc(scoreDelta?: ScoreDelta) {
    const {
      gamerIndex,
      vizBuilder: { sections },
      _scoreSeconds: currScoreSeconds,
      _prevSeconds,
      _trackMixer: { trackDuration },
    } = this
    const clockSeconds = Math.min(this._trackMixer.clockSeconds, trackDuration)
    const scoreSeconds = currScoreSeconds + (clockSeconds - _prevSeconds)
    this.dispatch(
      updateScore({
        gamerIndex,
        sections,
        scoreSeconds,
        scoreDelta,
      })
    )
  }

  handleSectionClick(sectionIndex: number, isOptionKey = false) {
    const section = this.vizBuilder.sections[sectionIndex]
    if (this.isPlaying) {
      if (this._trackMixer.isEditMode) {
        section.time = this._trackMixer.clockSeconds
        this.vizBuilder.updatePartHTML(section)
        this._updateDirty()
      }
    } else {
      if (isOptionKey && this._trackMixer.isEditMode) {
        section.time = 0 // clear section time
        this.vizBuilder.updatePartHTML(section)
        this._updateDirty()
      } else {
        let time = this._trackMixer.isEditMode ? section.time : section.referenceTime

        if (time === 0.0 && section.lines.length > 0) {
          let firstWord = section.lines[0].words[0]
          time = this._trackMixer.isEditMode ? firstWord.time : firstWord.referenceTime
        }
        const rollbackTo = Math.max(0, (time || 0) - DEFAULT_PREROLL_SECS)
        this._trackMixer.rewindTo(rollbackTo)
        // TODO: highlight? set target to first word, if any
      }
    }
  }

  handleWordScrub(targetWord: Target) {
    if (!this.isPlaying && this.playHead !== targetWord) {
      this.playHead = targetWord
      this.rewindToWord(true)
    }
  }

  handleWordClick(newTarget: Target) {
    if (this._trackMixer.isVizMode) {
      traverseLyricsSubset(this.vizBuilder.sections, scoreClearingVisitor, newTarget, this.playHead)
    }
    this._resetTarget(newTarget)
    this.playHead = this.target
    if (this._trackMixer.isVizMode) {
      this.rewindToWord(true)
    } else {
      this.rewindToTargetWithPreroll()
    }
  }

  setPartEvent({ eventCallback, part, useReferenceTime = false, deltaTime = 0.0 }: PartEventArgs) {
    const { eventID } = part
    if (eventID > 0) {
      // console.log(`clearing event[${part.eventID}] for ${part.label}`)
      Tone.Transport.clear(eventID)
      part.eventID = -1
    }
    const eventTime = getTimeForPart(part, useReferenceTime)
    if (!eventTime && !isZerothPart(part)) {
      return
    }
    const scheduleTime = (eventTime || 0) - (useReferenceTime ? deltaTime : 0)
    part.eventID = Tone.Transport.schedule(eventCallback, scheduleTime) // TODO: how getting cleared?
    // console.log(`setting event ${part.eventID} for ${part.label} at ${scheduleTime}s`)
  }
  removePartEvent(part: ModelPart) {
    const { eventID } = part
    if (eventID > 0) {
      Tone.Transport.clear(eventID)
      part.eventID = -1
    }
  }
  onHandlePartEvent(part: ModelPart) {
    const { isLine, index, isSection, elem } = part
    const sectionIndex = isSection
      ? index
      : isLine
      ? (part as Line).sectionIndex
      : (part as Word).sectionIndex
    const lineIndex = isSection ? -1 : isLine ? index : (part as Word).lineIndex

    this.playHead = getPartIndex(part)
    if (this._trackMixer.isPlayMode) {
      const millisSinceLastBlast = Date.now() - this.lastBlastTime
      const shouldResetTarget =
        this.isNoviceAssistEnabled && millisSinceLastBlast > BLAST_ASSIST_DELAY_MILLIS
      if (isLine && shouldResetTarget) {
        const isCurrTargetOnThisLine =
          this.target.sectionIndex === sectionIndex && this.target.lineIndex === lineIndex
        if (!isCurrTargetOnThisLine) {
          const nextLine = getNextLine(
            { sectionIndex, lineIndex, wordIndex: -1, elem: null },
            this.vizBuilder.sections
          )
          if (nextLine) {
            const nextWord = getNextWord(
              { sectionIndex, lineIndex: nextLine.lineIndex, wordIndex: -1, elem: null },
              this.vizBuilder.sections
            )
            if (nextWord.sectionIndex && nextWord.lineIndex) {
              this._resetTarget(nextWord)
              this.autoScroll(elem)
            }
          }
        }
      }
    } else if (this.isVisualPulseActiveWord || this.isAudioPulseActiveWord) {
      if (this._trackMixer.isVizMode) {
        this._resetTarget(this.playHead)
        if (!(part.isLine || part.isSection)) {
          part.elem?.classList.add('perfect', 'scored')
        }
      }
      this.autoScroll(elem)
      this._toggleSelection(part)

      // TODO: this will crash Chrome?
      if (this.isAudioPulseActiveWord) {
        this.synth.triggerAttackRelease('C5', '64n')
      }
    }
    // if (part.isSection) {
    //   console.log(`section: ${part.label}`)
    // }
  }

  resetPartTiming(part: ModelPart) {
    const { isLine, time, referenceTime } = part
    const vizUseReferenceTime: boolean = this._trackMixer.isVizMode && !time && !!referenceTime
    const useReferenceTime = isLine || vizUseReferenceTime
    this.setPartEvent({
      eventCallback: () => this.onHandlePartEvent(part),
      part,
      useReferenceTime,
    })
  }

  importLRC({
    lrcLines,
    asReference,
    isLoad,
    isReplaceLyrics,
  }: {
    asReference: boolean
    lrcLines: string[]
    isLoad: boolean
    isReplaceLyrics?: boolean
  }) {
    if (!lrcLines.length) {
      return
    }
    try {
      if (isReplaceLyrics) {
        const lyrics = extractLyrics(lrcLines)
        this.vizBuilder.updateLyrics(false, lyrics)
        this.dispatch(currentPlaySlice.actions.setLyrics(lyrics))
      }
      const numTimedWords = doImportLRC({
        lrcLines,
        asReference,
        sections: this.vizBuilder.sections,
      })
      this.vizBuilder.timedWordCount = numTimedWords
      if (asReference) {
        this.timedWordCount = numTimedWords
        // this.dispatch(currentPlaySlice.actions.setTimedWordCount(numTimedWords))
      }
      if (isReplaceLyrics) {
        this.vizBuilder.buildLyrics()
        this.recalc()
      } else if (isLoad) {
        this.vizBuilder.updateLyricElements()
        this.recalc()
      }
    } catch (err) {
      if (err instanceof Error) {
        console.log(err.message)
        return err.message
      }
    }
  }

  useReferenceTiming() {
    const lrcLines = selectCurrentLRC(getState())
    if (lrcLines) {
      this.importLRC({ lrcLines, asReference: false, isLoad: true })
    }
  }

  toggleSections(isShowSections: boolean) {
    const sections = document.querySelectorAll('.section > .label')
    for (let i = 0; i < sections.length; ++i) {
      sections[i].classList.toggle('mini', !isShowSections)
    }
    this.isShowSections = isShowSections
  }

  toggleNoviceAssist(isNoviceAssistEnabled: boolean) {
    this.isNoviceAssistEnabled = isNoviceAssistEnabled
  }

  toggleTimes(isShow: boolean) {
    const wordTimes = document.getElementsByClassName('word-timestamp')
    for (let i = 0; i < wordTimes.length; ++i) {
      wordTimes[i].classList.toggle('hidden', !isShow)
    }
    this.displayTimes = isShow
    this.dispatch(settingsSlice.actions.setIsShowTimes({ isShowTimes: isShow }))
  }

  toggleMasked(isRemoveMask: boolean) {
    const maskVisitor = {
      visitTrack: (isStart: boolean) => {},
      visitSection: (section: Section) => {},
      visitLine: (line: Line) => {},
      visitWord: ({ elem }: Word) => {
        if (!elem) {
          return
        }
        if (isRemoveMask) {
          elem.classList.remove('masked')
        } else {
          const { classList } = elem
          const addMask =
            !classList.contains('perfect') ||
            classList.contains('excellent') ||
            classList.contains('good') ||
            classList.contains('bad')
          if (addMask) {
            elem.classList.add('masked')
          }
        }
      },
    }
    traverseLyrics(this.vizBuilder.sections, maskVisitor)
    this.isShowLyrics = isRemoveMask
  }

  _toggleSelection(part: ModelPart | null = null, useVizBuilder = false) {
    if (this._trackMixer.isVizMode) {
      this.vizBuilder.updateViz()
      // return
    }
    const selectedWords = document.querySelectorAll('.selected')
    if (selectedWords) {
      // workaround to support arrays of elems from older browsers
      ;[].forEach.call(selectedWords, function (elem: HTMLElement) {
        elem.classList.remove('selected')
      })
    }
    const partToSelect = part ? part.elem : this.trackElem
    partToSelect?.classList.add('selected')
    if (part && !part.isSection && !part.isLine) {
      const { sectionIndex, lineIndex } = part as Word
      if (lineIndex >= 0) {
        const line = this.vizBuilder.sections[sectionIndex].lines[lineIndex]
        line.elem?.classList.add('selected')
      }
    }

    if (useVizBuilder) {
      // instead of check, rely on enabled/disabled vizBuilder
      this.vizBuilder.toggleSelection(part)
    }
  }

  appendSpelling(key: string) {
    const currWord = this.targetWord
    if (!currWord) {
      return
    }
    const { elem, label } = currWord
    const currSpelling = label.toLowerCase()
    const newSpelling = (this.spellingTarget.innerText + key).toLowerCase()
    if (currSpelling === newSpelling) {
      elem?.classList.remove('masked')
      if (!this._loadNextWord()) {
        // this._resetTarget() // TODO: decide whether should we go here
        this._loadPrevWord() // or is this clearer when done?
      }
    } else if (currSpelling.startsWith(newSpelling)) {
      elem?.classList.add('spelling')
      this.spellingTarget.innerText = newSpelling
    }
  }

  _clearWord(word: Word) {
    clearWordScoringClasses(word)
    this.vizBuilder.updatePartHTML(word)
    if (!this.isShowLyrics) {
      word.elem?.classList.add('masked')
    }
    if (word.eventID) {
      Tone.Transport.clear(word.eventID)
    }
  }

  _clearSection(section: Section) {
    this.vizBuilder.updatePartHTML(section)
    if (section.eventID && !this.vizBuilder.isVizSwitchingScheduled) {
      Tone.Transport.clear(section.eventID)
    }
  }

  // Handles auto-scrolling when target box moves outside the interaction container
  autoScroll(targetElem: HTMLElement | null) {
    if (this._interactionContainer) {
      doAutoScroll(this._interactionContainer, targetElem)
    }
  }

  set lyricFontSize(newSize: number) {
    this._lyricFontSize = newSize
    const sectionFontSize = Math.floor(SECTION_FONT_SIZE_FACTOR * newSize)
    document.documentElement.style.setProperty('--size-txt-lyrics', `${newSize}px`)
    document.documentElement.style.setProperty('--size-txt-section', `${sectionFontSize}px`)
  }

  get lyricFontSize() {
    return this._lyricFontSize
  }
}

export default Gamer
