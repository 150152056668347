import React from 'react'

// import {getSpotifyUriFromTrackInfo} from '../../util/track-utils'
// import {selectCurrentTrackImageUrl} from '../../selectors/session-selectors'
import Util from '../../util/util'
import { TrackPlayerCell } from '../../selectors/leaderboard-selectors'
import { getTrackPoints } from '../../util/score-utils'

type Props = {
  statsCell: TrackPlayerCell
  scoreVersion: number
}
const TrackBadge: React.FC<Props> = ({
  statsCell: { trackInfo, title: cellTitle },
  scoreVersion,
}) => {
  if (!trackInfo) {
    return (
      <div className="total">
        <div>{cellTitle}</div>
      </div>
    )
  }
  const {
    // slug: trackSlug,
    title = '[no track]',
    artist,
    wordCount,
    duration,
    emptyAirtime,
  } = trackInfo
  // const spotifyUri = getSpotifyUriFromTrackInfo(trackInfo)
  // const isUseSpotifyImage = hasSpotifyToken && spotifyUri
  // const trackImageUrl = useAppSelector(selectCurrentTrackImageUrl)
  const possibleTitleParts = title.split(' | ')
  const [trackArtist, trackTitle] =
    possibleTitleParts.length === 2 ? possibleTitleParts : [artist, title]
  const trackPointInfo = () => {
    const durationClockTime = Util.secondsToClock(duration, false, false)
    const scoreDuration = scoreVersion === 1 ? duration : duration - emptyAirtime
    const { pointsPerWord, maxPoints } = getTrackPoints(wordCount, scoreDuration)
    const pointsPerWordStr = duration > 0 ? pointsPerWord : '-'
    const pointsStr = duration > 0 ? maxPoints : '-'
    const durationStr = duration > 0 ? durationClockTime : '-'
    const divider = <span>{' / '}</span>

    return (
      <div className="trackInfo">
        <div className="score">
          <div className="points">{pointsStr}</div>
        </div>
        <div className="pointInfo">
          <span>{durationStr}</span>
          {divider}
          <span>{wordCount}</span>
          {divider}
          <span>{pointsPerWordStr}</span>
        </div>
      </div>
    )
  }

  return (
    <div className="trackBadge">
      {/*{isUseSpotifyImage && (*/}
      {/*  <div className="spotifyInfo">*/}
      {/*    <img src={trackImageUrl} alt="" />*/}
      {/*  </div>*/}
      {/*)}*/}
      <div className="currTrackTitle">
        <button className="title">{trackTitle}</button>
        <span className="artist">{trackArtist}</span>
      </div>
      {trackPointInfo()}
    </div>
  )
}

export default TrackBadge
