import firebase from 'firebase'
// import 'firebase/auth'
// import 'firebase/database'

import initRealtimeEndpoints from '../actions/initRealtimeEndpoints'
import { FIREBASE_AUDIENCE as audience } from '../constants/environment'
import getState, { AppDispatch } from '../reducers'
import chatTranscriptSlice from '../reducers/chatTranscriptSlice'
import { selectIsPlaying } from '../selectors/current-play-selectors'
import userManager from './UserManager'
import soundStatusSlice from '../reducers/soundStatusSlice'

const firebaseConfig = {
  apiKey: 'AIzaSyCS09KB43INx9bl0eXnNUTkVZI2sO2Q-yQ',
  authDomain: 'realtime1-75b84.firebaseapp.com',
  databaseURL: 'https://realtime1-75b84.firebaseio.com',
  projectId: 'realtime1-75b84',
  storageBucket: 'realtime1-75b84.appspot.com',
  messagingSenderId: '579806655960',
}

type ErrorHandler = (error: any) => void

class RealtimeService {
  private _database: firebase.database.Database | null
  private userHasInteracted: boolean
  private _dispatch: AppDispatch | null

  constructor() {
    this._dispatch = null
    this._database = null
    // this._peerListenerMap = {}
    this.userHasInteracted = false

    window.onmousemove = () => {
      this.userHasInteracted = true // TODO: move to state
    }
  }
  get dispatch() {
    if (!this._dispatch) {
      throw new Error('_dispatch not initialized!')
    }
    return this._dispatch
  }
  get database() {
    if (!this._database) {
      throw new Error('_database not initialized!')
    }
    return this._database
  }
  // getOrCreateBlasterPeer(username: string) {
  //   const existingPeer = selectBlasterPeer(username)(getState())
  //   if (existingPeer) {
  //     return existingPeer
  //   }
  //   const currentUsername = selectCurrentUsername(getState())
  //   const isCurrentUser = username === currentUsername
  //   // this._peerListenerMap[username] = { playlistListeners: {} }
  //   this.dispatch(blasterPeersSlice.actions.addBlasterPeer({ username, isCurrentUser }))
  //   const newPeer = selectBlasterPeer(username)(getState())
  //   return newPeer
  // }

  // get peerListenerMap() {
  //   return this._peerListenerMap
  // }

  init(dispatch: AppDispatch) {
    this._dispatch = dispatch
    firebase.initializeApp(firebaseConfig)
    this._database = firebase.database()
    userManager
      .callAPI('auth/firebase', true)
      .then((response: any) => {
        const token = response.firebaseToken
        firebase
          .auth()
          .signInWithCustomToken(token)
          .then(() => {
            dispatch(initRealtimeEndpoints()).then(() => {
              console.log('realtime ready')
            })
          })
          .catch((error) => {
            alert(`realtime sign in error: ${error}`)
          })
      })
      .catch((error) => {
        alert(`realtime unavailable: ${error}`) // TODO: propagate?
      })
  }

  endpoint(path: string) {
    return this.database.ref(`${audience}/${path}`)
  }

  endpointOnce(path: string, defaultValue: any = undefined): Promise<any> {
    const ref = this.database.ref(`${audience}/${path}`)
    return new Promise((resolve, reject) => {
      const onData = (snapshot: firebase.database.DataSnapshot) => {
        const value = snapshot.val()
        resolve(value || defaultValue)
      }
      const onError = (error: Object) => {
        reject(error)
      }
      ref.once('value', onData, onError)
    })
  }

  /* Wrapper for endpoint.set:
    If endpoint.set is called with bad parameters, it will throw without calling the handler
    e.g. if any object in the payload contains an undefined value (an easy bug to have).
    Log (or call custom handler) before rethrowing in case caller is swallowing exceptions
    e.g. a redux toolkit thunk which, by conveniently converting expections into .rejected actions,
    will result in exceptions being lost if action not explicitly handled in a reducer .
   */
  set(path: string, payload: any, errorHandler: ErrorHandler = this.defaultErrHandler) {
    try {
      return this.endpoint(path).set(payload, errorHandler)
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }

  // TODO: make this dev only until we do backend logging?
  defaultErrHandler(error: any) {
    if (error) {
      console.log('realtime error', error.message)
    }
  }

  alert(alertSoundName: string) {
    const isPlaying = selectIsPlaying(getState())
    const shouldPlay = this.userHasInteracted && !isPlaying
    if (shouldPlay) {
      this.dispatch(soundStatusSlice.actions.play(alertSoundName))
    }
  }

  _logUpdate(
    isUpdateFrom: boolean,
    username: string,
    updateText: string,
    alertSound: string,
    isError: boolean
  ) {
    this.alert(alertSound)

    if (!isUpdateFrom && !isError) {
      // this.messageInput.value = '';
    } // TODO: more error handling?

    this.dispatch(
      chatTranscriptSlice.actions.addMessage({
        username,
        isFrom: isUpdateFrom,
        text: updateText,
      })
    )

    // this.transcript.scrollTop = this.transcript.scrollHeight;
  }

  logUpdateTo(userName: string, updateText: string, alertSound: string, isError: boolean) {
    this._logUpdate(false, userName, updateText, alertSound, isError)
  }

  logUpdateFrom(userName: string, updateText: string, alertSound: string, isError: boolean) {
    this._logUpdate(true, userName, updateText, alertSound, isError)
  }
}

const defaultRealtimeService = new RealtimeService()
export default defaultRealtimeService
