import React, { ChangeEvent } from 'react'

import {
  addOrUpdateMatchInfo,
  resyncOwnedMatchStatus,
} from '../../../actions/social/leaderboard-actions'
import {
  HOST_VISIBILITY_DEMO,
  HOST_VISIBILITY_HIDE,
  HOST_VISIBILITY_PLAY,
} from '../../../constants/constants'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { selectIsOptionKey } from '../../../selectors/settings-selectors'
import { HostVisibility, MatchInfo } from '../../../types'
import Util from '../../../util/util'
import { CheckboxWithLabel, TextInputWithLabel } from '../../widgets/TextInputWithLabel'

const hostVisibilityRadio: { name: string; key: HostVisibility }[] = [
  { name: 'Play', key: HOST_VISIBILITY_PLAY },
  { name: 'Demo', key: HOST_VISIBILITY_DEMO },
  { name: 'Hide', key: HOST_VISIBILITY_HIDE },
]

type Props = {
  currUserId: string
  matchOwner: string
  matchSlug: string
  isOwnedMatch: boolean
  matchInfo: MatchInfo
}

const MatchInfoSection = ({
  currUserId,
  matchOwner,
  matchSlug,
  matchInfo,
  isOwnedMatch,
}: Props) => {
  const dispatch = useAppDispatch()
  const isNew = !matchSlug
  const isOwner = currUserId === matchOwner
  const [workingMatchSlug] = React.useState(isNew ? Util.generateId() : matchSlug)
  const isReadOnly = !isOwner
  const isOptionKey = useAppSelector(selectIsOptionKey(0)) // TODO fix
  const requestingResync = isOwnedMatch && isOptionKey
  const {
    title = '???',
    isShareable,
    isCopyable,
    hostVisibility = HOST_VISIBILITY_DEMO,
  } = matchInfo || {}
  const [managedIsShareable, setManagedIsShareable] = React.useState(isNew ? true : !!isShareable)
  const [managedIsCopyable, setManagedIsCopyable] = React.useState(isNew ? false : !!isCopyable)
  const [managedHostVisibility, setManagedHostVisibility] =
    React.useState<HostVisibility>(hostVisibility)
  const [managedMatchTitle, setWorkingMatchTitle] = React.useState(isNew ? 'New Match' : title)
  const [isDirty, setIsDirty] = React.useState(false)
  const onTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWorkingMatchTitle(event.target.value)
    setIsDirty(true)
  }

  const Sharing = () => {
    const onShareableChanged = (event: ChangeEvent<HTMLInputElement>) => {
      setManagedIsShareable(event.target.checked)
      setIsDirty(true) // TODO: compare with saved (otherwise, we're just implementing "touched"?)
    }
    const onCopyableChanged = (event: ChangeEvent<HTMLInputElement>) => {
      setManagedIsCopyable(event.target.checked)
      setIsDirty(true) // TODO: compare with saved (otherwise, we're just implementing "touched"?)
    }
    return (
      <div className="visibility">
        <label>Sharing:</label>
        <div>
          <div className="help">any match player...</div>
          <CheckboxWithLabel
            id="isShareable"
            label="Can Invite Others"
            disabled={!isOwner || isOwnedMatch}
            value={managedIsShareable}
            onChange={onShareableChanged}
          />
          <div className="subCheckbox">
            <CheckboxWithLabel
              id="isCopyable"
              label="Can Duplicate"
              disabled={!isOwner || isOwnedMatch}
              value={managedIsCopyable}
              onChange={onCopyableChanged}
            />
          </div>
        </div>
      </div>
    )
  }
  const HostVisibility = () => {
    const onChange = (key: HostVisibility) => {
      setManagedHostVisibility(key)
      setIsDirty(true) // TODO: compare with saved (otherwise, we're just implementing "touched"?)
    }
    return (
      <div className="hostVisibility">
        <label>Host Visibility: </label>
        <div>
          {hostVisibilityRadio.map(({ name, key }, i) => (
            <label className="radio" key={key}>
              <input
                type="radio"
                name="hostBlastMode"
                value={i}
                checked={managedHostVisibility === key}
                onChange={() => onChange(key)}
              />
              {name}
            </label>
          ))}
        </div>
      </div>
    )
  }

  const onSetTitleRef = (elem: HTMLInputElement) => {
    if (isNew && elem) {
      elem.select()
    }
  }

  const onAddOrUpdateMatch = () => {
    const dateProp = isNew ? 'created' : 'modified'
    const compoundMatchSlug = `${matchOwner}/${workingMatchSlug}`
    const updatedMatchInfo = {
      ...matchInfo,
      slug: compoundMatchSlug,
      title: managedMatchTitle,
      isShareable: managedIsShareable,
      isCopyable: managedIsCopyable,
      hostVisibility: managedHostVisibility,
      [dateProp]: Date.now(),
    }
    if (requestingResync) {
      dispatch(resyncOwnedMatchStatus())
    }
    dispatch(addOrUpdateMatchInfo({ matchInfo: updatedMatchInfo }))
    setIsDirty(false)
  }
  const getButtons = () => {
    return (
      <div className="buttons">
        <button onClick={onAddOrUpdateMatch} disabled={!isDirty}>
          Save Changes
        </button>
      </div>
    )
  }

  return (
    <div>
      <div>
        <label htmlFor="matchSlug">Blaster ID: </label>
        <input
          id="matchSlug"
          className="textLabel slug"
          type="text"
          readOnly
          value={workingMatchSlug}
        />
      </div>
      <TextInputWithLabel id="matchOwner" label="Host" isReadOnly value={matchOwner} />
      <TextInputWithLabel
        id="audioTitle"
        label="Title"
        isReadOnly={isReadOnly}
        value={managedMatchTitle}
        onBlur={onTitleChange}
        onRef={onSetTitleRef}
      />
      {isOwner && !isOwnedMatch && <Sharing />}
      {isOwner && !isOwnedMatch && <HostVisibility />}
      {isOwner && getButtons()}
    </div>
  )
}

export default MatchInfoSection
