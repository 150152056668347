import cx from 'classnames'
import React, { ChangeEvent, createRef, useState } from 'react'

import { adjustTrackScore } from '../../../actions/updateScore'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { selectCurrentPlaylistSlug } from '../../../selectors/current-play-selectors'
import { LeaderboardCell, selectMatchStatsByTrack } from '../../../selectors/leaderboard-selectors'
import { selectScoreVersion } from '../../../selectors/session-selectors'
import { PlayerPath, TrackPath } from '../../../types'
import TrackBadge from '../../widgets/TrackBadge'
import GuestPlayerSetup from '../match-info/GuestPlayerSetup'
import { NumberInputWithLabel } from '../../widgets/TextInputWithLabel'
import { selectCurrentUsername } from '../../../selectors/session-selectors'

type Props = { matchSlug: string }
type SelectedCell = {
  col: number
  row: number
  playlistSlug: string
  trackSlug?: string
}
const StatsByTrackTable = ({ matchSlug: compoundMatchSlug }: Props) => {
  const dispatch = useAppDispatch()
  const [matchOwner, matchSlug] = compoundMatchSlug.split('/')
  const currUsername = useAppSelector(selectCurrentUsername)
  const isOwnedMatch = matchOwner === currUsername
  const scoreVersion = useAppSelector(selectScoreVersion)
  const leaderboardData = useAppSelector(selectMatchStatsByTrack({ matchOwner, matchSlug }))
  const {
    colLabels,
    rowLabels,
    dataRows,
    playerCounts: { guestCount },
  } = leaderboardData
  const currentPlaylistSlug = useAppSelector(selectCurrentPlaylistSlug)
  const [selectedScoreInfo, setSelectedScoreInfo] = useState<SelectedCell | null>(null)
  const scoreEditRef = createRef<HTMLInputElement>()
  React.useEffect(() => {
    const elem = scoreEditRef.current
    if (elem) {
      elem.select()
    }
  })

  let inDefaultOpen = false
  const initialRowState = rowLabels.map(({ slug, trackInfo }, index) => {
    if (!trackInfo) {
      inDefaultOpen = slug === currentPlaylistSlug
    }
    return inDefaultOpen
  })
  const [playlistRowState, setPlaylistRowState] = useState<boolean[]>(initialRowState)

  const togglePlaylist = (playlistRow: number) => {
    const newState = !playlistRowState[playlistRow]
    const newRowState = [...playlistRowState]
    newRowState[playlistRow] = newState
    for (let row = playlistRow + 1; row < rowLabels.length; row++) {
      const { trackInfo } = rowLabels[row]
      if (!trackInfo) {
        break
      }
      newRowState[row] = newState
    }
    setPlaylistRowState(newRowState)
  }
  const getRowLabel = (rowIndex: number) => {
    const { title, trackInfo } = rowLabels[rowIndex]
    if (!!trackInfo && !playlistRowState[rowIndex]) {
      return null
    }
    return (
      <td className="sticky-label">
        {trackInfo ? (
          <TrackBadge statsCell={rowLabels[rowIndex]} scoreVersion={scoreVersion} />
        ) : (
          <div onClick={() => togglePlaylist(rowIndex)} className="playlistInfo">
            {title}
          </div>
        )}
      </td>
    )
  }
  const getHeader = () => {
    const badgeClass = cx('playerBadge', { guestName: guestCount > 0 })
    return (
      <tr>
        <th className="sticky-header corner">
          <GuestPlayerSetup compoundMatchSlug={compoundMatchSlug} isAllowNewPlayer={false} />
        </th>
        {colLabels.map(({ slug, title, score, scoreRank }, index) => (
          <th key={index} className="sticky-header">
            <div className="playerScore">
              <div className="matchRank">{index + 1}</div>
              <div className={badgeClass} title={slug}>
                {title}
              </div>
              <div className="scoreWrapper">
                <div className={cx('score', { [scoreRank || '']: true })}>{score}</div>
              </div>
            </div>
          </th>
        ))}
      </tr>
    )
  }
  const onScoreEdit = (event: ChangeEvent<HTMLInputElement>) => {
    if (selectedScoreInfo) {
      const newScore = parseInt(event.target.value)
      const { row, col, playlistSlug, trackSlug } = selectedScoreInfo
      const { slug: compoundPlayerSlug } = colLabels[col]
      const currentScore = dataRows[row][col].score
      if (newScore !== currentScore) {
        const [username, player] = compoundPlayerSlug.split('/')
        const playerPath: PlayerPath = { matchOwner, matchSlug, username, player }
        const trackPath: TrackPath = { matchSlug: compoundMatchSlug, playlistSlug, trackSlug }
        dispatch(adjustTrackScore({ playerPath, trackPath, newTopScore: newScore }))
        setSelectedScoreInfo(null)
      }
    }
  }
  const getPlaylistForRow = (rowIndex: number) => {
    for (let i = rowIndex; i >= 0; i--) {
      const { slug, trackInfo } = rowLabels[i]
      if (!trackInfo) {
        return slug
      }
    }
    return ''
  }
  const getBody = () => {
    return dataRows.map((row, rowIndex) => {
      const { slug, trackInfo } = rowLabels[rowIndex]
      const isTrack = !!trackInfo
      if (isTrack && !playlistRowState[rowIndex]) {
        return null
      }
      const trackSlug = isTrack ? slug : undefined
      return (
        <tr key={rowIndex}>
          {getRowLabel(rowIndex)}
          {row.map((cell: LeaderboardCell, colIndex: number) => {
            const onClick = () => {
              if (isOwnedMatch && trackSlug) {
                const playlistSlug = getPlaylistForRow(rowIndex)
                setSelectedScoreInfo({ row: rowIndex, col: colIndex, playlistSlug, trackSlug })
              }
            }
            const isSelected =
              selectedScoreInfo &&
              selectedScoreInfo.row === rowIndex &&
              selectedScoreInfo.col === colIndex
            const className = cx('playerScore', { isPlaylist: !isTrack })
            return (
              <td key={colIndex}>
                <div className={className} onClick={onClick}>
                  {isSelected ? (
                    <NumberInputWithLabel
                      label=""
                      value={cell.score}
                      onBlur={onScoreEdit}
                      onRef={scoreEditRef}
                    />
                  ) : (
                    cell.score > 0 && (
                      <div className={cx('score', { [cell.scoreRank || '']: true })}>
                        {cell.score}
                      </div>
                    )
                  )}
                </div>
              </td>
            )
          })}
        </tr>
      )
    })
  }

  return (
    <div className="statsByTrackTable">
      <div className="table-container">
        <table className="table">
          <thead>{getHeader()}</thead>
          <tbody>{getBody()}</tbody>
        </table>
      </div>
    </div>
  )
}

export default StatsByTrackTable
