import { defaultModelPart, PART_ID_PREFIX } from '../../constants/constants'
import { Section, Line, Word } from '../../types'
import { SIMPLE_LINE_BREAK_REGEX } from '../../constants/utility-constants'

const SECTION_REGEX = /[[](.*)[\]]/ // TODO: investigate this warning: '[\]]' can be simplified to '\]'
const TEXT_LINE_MATCH = /\[\[(.*)\]\]/

const makePartId = (sectionIndex: number, lineIndex: number, wordIndex: number) => {
  if (wordIndex >= 0) {
    return `${PART_ID_PREFIX}-${sectionIndex}-${lineIndex}-${wordIndex}`
  }
  if (lineIndex >= 0) {
    return `${PART_ID_PREFIX}-${sectionIndex}-${lineIndex}`
  }
  return `${PART_ID_PREFIX}-${sectionIndex}`
}

const splitLinesAndRemoveMeta = (txtWithMeta: string) => {
  const textLines = txtWithMeta.split(SIMPLE_LINE_BREAK_REGEX)
  const firstNonMetaAt = textLines.findIndex((line) => {
    return !line.startsWith('[[')
  })
  return textLines.slice(firstNonMetaAt)
}

const splitLyrics = (lyrics: string) => {
  const lyricState: { numWords: number; sections: Section[]; title: string } = {
    numWords: 0,
    sections: [],
    title: '',
  }
  const textLines = lyrics.split(SIMPLE_LINE_BREAK_REGEX)
  let currSection: Section | null = null

  textLines.forEach((textLine, lineNum) => {
    if (lineNum === 0) {
      const titleMatch = textLine.match(TEXT_LINE_MATCH)
      if (titleMatch) {
        lyricState.title = titleMatch[1]
        return
      }
    }
    if (textLine === '') {
      return // short-circuit
    }
    textLine = textLine.trim()

    const sectionMatch = textLine.match(SECTION_REGEX)
    if (sectionMatch || !currSection) {
      currSection = {
        ...defaultModelPart,
        isSection: true,
        index: currSection ? currSection.index + 1 : 0,
        label: sectionMatch ? sectionMatch[1] : 'defaultSection',
        lines: [],
        container: null,
      }

      lyricState.sections.push(currSection)
      return
    }
    const sectionIndex = currSection.index
    const lineIndex = currSection.lines.length
    const line: Line = {
      ...defaultModelPart,
      isLine: true,
      sectionIndex,
      index: lineIndex,
      label: 'line ' + lineIndex,
      words: [],
    }
    currSection.lines.push(line)

    const words = textLine.split(' ')
    words.forEach((wordText, i) => {
      const word: Word = {
        ...defaultModelPart,
        sectionIndex,
        lineIndex,
        index: i,
        label: wordText,
      }

      line.words.push(word)
      lyricState.numWords++
    })
  })

  return lyricState
}

export default splitLyrics
export { makePartId, splitLinesAndRemoveMeta }
