import UploadProgress from '../../components/widgets/UploadProgress'
import userManager from '../../services/UserManager'
import {
  DEFAULT_AUDIO_FILE_TYPE,
  DEFAULT_IMAGE_FILE_TYPE,
  defaultTrackInfo,
} from '../../constants/constants'
import blasterPeersSlice from '../../reducers/blasterPeersSlice'
import { selectTrackInfo } from '../../selectors/blaster-peer-selectors'
import {
  selectCurrentLyrics,
  selectCurrentTrackInfo,
  selectCurrentTrackMeta,
} from '../../selectors/current-play-selectors'
import { selectCurrentUsername } from '../../selectors/session-selectors'
import { updateTrack } from '../social/peer-playlists'
import {
  updateLocalTiming,
  updateDirty,
  updateLocalTimingURL,
  updateLocalAudioURL,
  updateLocalOwner,
  updateLocalImageURL,
} from './update-local-track'
import { selectIsInLocalTracks } from '../../selectors/local-authoring-selectors'
import { createAppAsyncThunk } from '../../reducers'
import { UploadHelpers } from '../../types'

const uploadTrackTiming = createAppAsyncThunk(
  'upload/timing',
  async (timing: string, { dispatch, getState }) => {
    const state = getState()
    const meta = selectCurrentTrackMeta(state)
    const trackSlug = state.currentPlay.trackInfo.slug
    const timingWithMeta = meta.concat(timing)
    dispatch(updateLocalTiming(trackSlug, timing))

    const uploadProgress = new UploadProgress('timing-uploadProgress')
    uploadProgress.start()
    const params = userManager.getS3BucketParams(trackSlug, 'text/lrc', timingWithMeta)

    return userManager
      .doS3Upload(params, (percentUploaded: number) => {
        uploadProgress.doProgress(percentUploaded)
      })
      .then(
        (remoteUrl: string) => {
          uploadProgress.stop()
          dispatch(updateLocalTimingURL(trackSlug, remoteUrl))
          dispatch(updateDirty(trackSlug, { isTimingDirty: false }))
        },
        (err) => {
          uploadProgress.stop(err)
        }
      )
  }
)

const uploadTrackManifest = createAppAsyncThunk(
  'upload/manifest',
  async (
    {
      localAudioFileType,
      localImageFileType,
      cloneFrom,
    }: { localAudioFileType: string; localImageFileType: string; cloneFrom: string },
    { dispatch, getState }
  ) => {
    const state = getState()
    const trackInfo = selectCurrentTrackInfo(state)
    const { slug: trackSlug } = trackInfo
    const manifest = JSON.stringify(
      userManager.getPackageData({ trackSlug, localAudioFileType, localImageFileType, cloneFrom })
    )
    if (!manifest) {
      return Promise.resolve()
    }
    const uploadProgress = new UploadProgress('manifest-uploadProgress')
    uploadProgress.start()
    const params = userManager.getS3BucketParams(trackSlug, 'text/json', manifest)

    return userManager
      .doS3Upload(params, (percentUploaded: number) => {
        uploadProgress.doProgress(percentUploaded)
      })
      .then(
        (remoteUrl) => {
          uploadProgress.stop()
          dispatch(updateDirty(trackSlug, { isInfoDirty: false }))
          dispatch(updateTrack({ trackInfo })) // TODO: needed??
        },
        function (err) {
          uploadProgress.stop(err)
          Promise.reject(err)
        }
      )
  }
)

const uploadTrackLyrics = createAppAsyncThunk(
  'upload/lyrics',
  async (_, { dispatch, getState }) => {
    const state = getState()
    const meta = selectCurrentTrackMeta(state)
    const lyrics = selectCurrentLyrics(state)
    const lyricsWithMeta = meta.concat(lyrics)
    const trackInfo = selectCurrentTrackInfo(state)
    const { slug: trackSlug } = trackInfo
    const uploadProgress = new UploadProgress('lyrics-uploadProgress')
    uploadProgress.start()
    const params = userManager.getS3BucketParams(trackSlug, 'text/plain', lyricsWithMeta)

    return userManager
      .doS3Upload(params, (percentUploaded: number) => {
        uploadProgress.doProgress(percentUploaded)
      })
      .then(
        (remoteUrl) => {
          uploadProgress.stop()
          dispatch(updateDirty(trackSlug, { isLyricsDirty: false }))
          dispatch(updateTrack({ trackInfo }))
        },
        function (err) {
          uploadProgress.stop(err)
          Promise.reject(err)
        }
      )
  }
)

const uploadTrackAudio = createAppAsyncThunk(
  'upload/audio',
  async (trackFile: File, { dispatch, getState }) => {
    const state = getState()
    const { trackInfo } = state.currentPlay
    const { slug: trackSlug } = trackInfo
    if (!trackFile) {
      return Promise.reject() // none selected
    }
    const audioFileType = trackFile.type || DEFAULT_AUDIO_FILE_TYPE
    const uploadProgress = new UploadProgress('audio-uploadProgress')
    uploadProgress.start()
    const params = userManager.getS3BucketParams(trackSlug, audioFileType, trackFile)

    return userManager
      .doS3Upload(params, (percentUploaded: number) => {
        uploadProgress.doProgress(percentUploaded)
      })
      .then(
        (remoteUrl) => {
          uploadProgress.stop()
          dispatch(updateLocalAudioURL(trackSlug, remoteUrl))
          dispatch(updateDirty(trackSlug, { isAudioDirty: false }))
        },
        (err) => {
          uploadProgress.stop(err)
        }
      )
  }
)

const uploadTrackImage = createAppAsyncThunk(
  'upload/image',
  async (trackFile: File, { dispatch, getState }) => {
    const state = getState()
    const { trackInfo } = state.currentPlay
    const { slug: trackSlug } = trackInfo
    if (!trackFile) {
      return Promise.reject() // none selected
    }
    const imageFileType = trackFile.type || DEFAULT_IMAGE_FILE_TYPE
    const uploadProgress = new UploadProgress('image-uploadProgress')
    uploadProgress.start()
    const params = userManager.getS3BucketParams(trackSlug, imageFileType, trackFile)

    return userManager
      .doS3Upload(params, (percentUploaded: number) => {
        uploadProgress.doProgress(percentUploaded)
      })
      .then(
        (remoteUrl) => {
          uploadProgress.stop()
          dispatch(updateLocalImageURL(trackSlug, remoteUrl))
          dispatch(updateDirty(trackSlug, { isImageDirty: false }))
        },
        (err) => {
          uploadProgress.stop(err)
        }
      )
  }
)

const uploadAllDirty = createAppAsyncThunk(
  'upload/all-dirty',
  async (
    {
      uploadHelpers,
      effectiveDirtyState,
    }: { uploadHelpers: UploadHelpers; effectiveDirtyState: any },
    { dispatch, getState }
  ) => {
    const { getLocalAudioFile, getLocalImageFile, getCurrentLRC } = uploadHelpers
    const state = getState()
    const currentUsername = selectCurrentUsername(state)
    const currentTrackInfo = selectCurrentTrackInfo(state)
    const { slug, owner: currentOwner } = currentTrackInfo
    const cloneFrom = currentUsername === currentOwner ? '' : currentOwner
    if (cloneFrom) {
      dispatch(updateLocalOwner(slug, currentUsername))
    }
    const dirtyState = effectiveDirtyState || state.currentPlay.dirtyState
    const uploadPromises: Promise<any>[] = []
    if (!cloneFrom && dirtyState.isAudioDirty) {
      const audioFile = getLocalAudioFile()
      if (audioFile) {
        uploadPromises.push(dispatch(uploadTrackAudio(audioFile)))
      } else {
        console.warn('isAudioDirty but no local audio file?')
      }
    }
    if (!cloneFrom && dirtyState.isImageDirty) {
      const imageFile = getLocalImageFile()
      if (imageFile) {
        uploadPromises.push(dispatch(uploadTrackImage(imageFile)))
      } else {
        console.warn('isImageDirty but no local image file?')
      }
    }
    if (cloneFrom || dirtyState.isInfoDirty) {
      const audioFile = getLocalAudioFile()
      const localAudioFileType = audioFile ? audioFile.type : ''
      const imageFile = getLocalImageFile()
      const localImageFileType = imageFile ? imageFile.type : ''
      uploadPromises.push(
        dispatch(uploadTrackManifest({ localAudioFileType, localImageFileType, cloneFrom }))
      )
    }
    if (cloneFrom || dirtyState.isLyricsDirty) {
      uploadPromises.push(dispatch(uploadTrackLyrics()))
    }
    if (cloneFrom || dirtyState.isTimingDirty) {
      const timing = getCurrentLRC()
      uploadPromises.push(dispatch(uploadTrackTiming(timing)))
    }
    return Promise.all(uploadPromises)
  }
)

const adoptCurrentTrack = createAppAsyncThunk(
  'upload/adopt-current-track',
  async (_, { dispatch, getState }) => {
    const state = getState()
    const username = selectCurrentUsername(state)
    const currTrackInfo = selectCurrentTrackInfo(state)
    const { slug, owner: currentTrackOwner } = currTrackInfo
    const ownedTrackInfo = selectTrackInfo(username, slug)(state) || defaultTrackInfo
    const { owner: ownedTrackOwner } = ownedTrackInfo
    const localTrackInState = selectIsInLocalTracks(slug)(state)
    if (localTrackInState || !ownedTrackOwner || currentTrackOwner === ownedTrackOwner) {
      console.log(`can't adopt ${currentTrackOwner}'s track ${slug}`)
      return
    }
    dispatch(
      blasterPeersSlice.actions.updateTrack({
        username,
        trackInfo: {
          ...ownedTrackInfo,
          owner: currentTrackOwner,
        },
      })
    )
  }
)

export { adoptCurrentTrack, uploadAllDirty }
