import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import React, { ChangeEvent, createRef, useEffect, useState } from 'react'
import { ReactSortable } from 'react-sortablejs'

import { DEFAULT_PLAYLIST_KEY, defaultPlaylistInfo } from '../../constants/constants'
import { updatePlaylist } from '../../actions/social/peer-playlists'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { selectCurrentUser, selectOwnedPlaylistMap } from '../../selectors/blaster-peer-selectors'
import { selectMatchTitle, selectPlaylistMap } from '../../selectors/match-selectors'
import { selectScoreVersion } from '../../selectors/session-selectors'
import { MinimalTrackInfo, TrackInfo } from '../../types'
import { getTrackPoints } from '../../util/score-utils'
import { onModalContainerRef } from '../../util/scrolling'
import { getTrackArtistAndTitle } from '../../util/track-utils'
import Util from '../../util/util'
import CloseIcon from '../widgets/CloseIcon'
import PlaylistSortRadio from '../widgets/PlaylistSortRadio'
import {
  NumberInputWithLabel,
  SelectWithLabel,
  TextInputWithLabel,
} from '../widgets/TextInputWithLabel'

type Props = {
  matchSlug: string
  playlistSlug: string | null
  playlistTitle: string | null
  currTrackInfo?: TrackInfo
  workingTrackOrder?: string[]
  onClose: (needUpdate?: boolean) => void
}
type PlaylistTrack = MinimalTrackInfo & {
  id: string
  otherOwner: string
  duration: number
  wordCount: number
  trackPoints: number
  disabled: boolean
  isMutable: boolean
}

export enum SortBy {
  custom = 'custom',
  artist = 'artist',
  title = 'title',
  points = 'points',
}

const PlaylistModal = ({
  matchSlug,
  playlistSlug,
  playlistTitle,
  currTrackInfo,
  workingTrackOrder,
  onClose,
}: Props) => {
  const dispatch = useAppDispatch()
  const scoreVersion = useAppSelector(selectScoreVersion)
  const currUser = useAppSelector(selectCurrentUser)
  const { tracks, username, allTracksPlaylistSlug } = currUser
  const matchTitle = useAppSelector(selectMatchTitle(username, matchSlug))
  const ownedPlaylistMap = useAppSelector(selectOwnedPlaylistMap(username))
  const playlistMap = useAppSelector(selectPlaylistMap(`${username}/${matchSlug}`))
  const isNew = !playlistSlug
  const isLocked = playlistSlug === DEFAULT_PLAYLIST_KEY
  const [isDirty, setIsDirty] = useState(false)
  const [selectFromPlaylistSlug, setSelectFromPlaylistSlug] = useState(allTracksPlaylistSlug)
  const playlistItems = Object.values(ownedPlaylistMap).map(({ slug, title }) => {
    return { value: slug, name: title }
  })
  const [workingPlaylistSlug] = useState(isNew ? Util.generateId() : playlistSlug)
  const [workingPlaylistTitle, setWorkingPlaylistTitle] = useState(
    isNew || !playlistTitle ? 'Untitled Playlist' : playlistTitle
  )
  const existingPlaylist = playlistMap[workingPlaylistSlug] || {}
  const { perTrackPlayLimit: existingPerTrackPlayLimit = -1 } = existingPlaylist
  const [workingPerTrackPlayLimit, setWorkingPerTrackPlayLimit] =
    useState(existingPerTrackPlayLimit)
  const [workingSortBy, setWorkingSortBy] = useState(SortBy.custom)
  const [chooseFromSortBy, setChooseFromSortBy] = useState(SortBy.custom)
  const getTrackInfo = (slug: string, isDisabled: boolean, isMutable: boolean) => {
    const { slug: currTrackSlug } = currTrackInfo || {}
    const trackInfo = slug === currTrackSlug ? currTrackInfo : tracks[slug]
    const { owner = '', duration = 0, wordCount = 0, emptyAirtime = 0 } = trackInfo || {}
    const otherOwner = username === owner ? '' : owner
    const { artist, title } = getTrackArtistAndTitle(trackInfo)
    const scoreDuration = scoreVersion === 1 ? duration : duration - emptyAirtime
    const { maxPoints: trackPoints } = getTrackPoints(wordCount, scoreDuration)
    return {
      id: slug,
      artist,
      title,
      duration,
      wordCount,
      trackPoints,
      otherOwner,
      disabled: isLocked || isDisabled,
      isMutable,
    }
  }
  const initTracks2 = () => {
    const { slug: currTrackSlug } = currTrackInfo || {}
    const defaultTrackOrder = isNew ? (currTrackSlug ? [currTrackSlug] : []) : []
    const workingPlaylistTrackOrder = defaultTrackOrder
    if (workingTrackOrder) {
      workingPlaylistTrackOrder.push(...workingTrackOrder)
    } else {
      const { trackOrder: existingPlaylistTrackOrder = [] } = existingPlaylist
      if (workingPlaylistTrackOrder) {
        defaultTrackOrder.push(...existingPlaylistTrackOrder)
      }
    }
    const currTracks = workingPlaylistTrackOrder.map((slug) => {
      return getTrackInfo(slug, false, true)
    })
    return currTracks
  }
  const [isTracksInited, setIsTracksInited] = React.useState(false)
  const [workingTracks, setWorkingTracks] = React.useState<PlaylistTrack[]>(initTracks2())
  const [tracksToChooseFrom, setTracksToChooseFrom] = React.useState<PlaylistTrack[]>([])

  useEffect(() => {
    const { trackOrder: selectedPlaylistTrackOrder = [] } = ownedPlaylistMap[selectFromPlaylistSlug]
    const selectedPlaylistTracks = selectedPlaylistTrackOrder.map((slug) => {
      const isDisabled = workingTracks.findIndex(({ id }) => id === slug) >= 0
      return getTrackInfo(slug, isDisabled, false)
    })
    sortTracks(selectedPlaylistTracks, chooseFromSortBy)
    setTracksToChooseFrom(selectedPlaylistTracks)
  }, [selectFromPlaylistSlug, ownedPlaylistMap, workingTracks, chooseFromSortBy])

  const sortWorkingTracks = (sortTracksBy: SortBy) => {
    sortTracks(workingTracks, sortTracksBy)
    setWorkingTracks([...workingTracks])
    setWorkingSortBy(sortTracksBy)
  }
  const sortChooseFromTracks = (sortTracksBy: SortBy) => {
    sortTracks(tracksToChooseFrom, sortTracksBy)
    setTracksToChooseFrom([...tracksToChooseFrom])
    setChooseFromSortBy(sortTracksBy)
  }
  const checkBoxIdForSlug = (slug: string) => `playlist-track-${slug}`
  const sortTracks = (tracksToSort: PlaylistTrack[], sortTracksBy: SortBy) => {
    if (sortTracksBy === SortBy.custom) {
      return
    }
    tracksToSort.sort((trackInfoA: PlaylistTrack, trackInfoB: PlaylistTrack) => {
      const { title: titleA, artist: artistA } = getTrackArtistAndTitle(trackInfoA)
      const { title: titleB, artist: artistB } = getTrackArtistAndTitle(trackInfoB)
      if (sortTracksBy === SortBy.points) {
        return trackInfoB.trackPoints - trackInfoA.trackPoints
      } else {
        const trackA =
          sortTracksBy === SortBy.title ? `${titleA}-${artistA}` : `${artistA}-${titleA}`
        const trackB =
          sortTracksBy === SortBy.title ? `${titleB}-${artistB}` : `${artistB}-${titleB}`
        return trackA.localeCompare(trackB)
      }
    })
  }

  const removeTrack = (trackSlug: string) => {
    const workingIndex = workingTracks.findIndex(({ id }) => id === trackSlug)
    if (workingIndex >= 0) {
      workingTracks.splice(workingIndex, 1)
      setWorkingTracks([...workingTracks])
    }
    const chooseFromTrack = tracksToChooseFrom.find(({ id }) => id === trackSlug)
    if (chooseFromTrack) {
      chooseFromTrack.disabled = false
      setTracksToChooseFrom([...tracksToChooseFrom])
    }
  }
  const insertTrack = (trackSlug: string) => {
    const track = tracksToChooseFrom.find(({ id }) => id === trackSlug)
    if (track) {
      workingTracks.push({ ...track, isMutable: true, disabled: false })
      track.disabled = true
    }
    sortWorkingTracks(workingSortBy)
    setWorkingTracks([...workingTracks])
    setTracksToChooseFrom([...tracksToChooseFrom])
  }
  // TODO: support songs appearing more than once in a playlist??
  const addTrack = ({
    id,
    artist,
    title,
    otherOwner,
    disabled,
    trackPoints,
    isMutable,
  }: PlaylistTrack) => {
    const checkboxId = checkBoxIdForSlug(id)
    return (
      <div key={id} className="sortable-list-item handle" data-id={id}>
        {!isMutable && (
          <button
            className="trash"
            onClick={() => insertTrack(id)}
            title="add to Set"
            disabled={disabled}
          >
            <FontAwesomeIcon size="xs" icon={faPlus as IconProp} />
          </button>
        )}
        <label htmlFor={checkboxId} className="handle track">
          <div>{title}</div>
          <div className="artist">{artist}</div>
        </label>
        {otherOwner && <div className="otherOwner">{otherOwner}</div>}
        <div>{trackPoints}</div>
        {isMutable && (
          <button
            className="trash"
            title="remove from Set"
            onClick={() => removeTrack(id)}
            disabled={disabled}
          >
            <FontAwesomeIcon size="xs" icon={faTrash as IconProp} />
          </button>
        )}
      </div>
    )
  }
  const onSwitchSelectFrom = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectFromPlaylistSlug(event.target.value)
  }

  const onAddOrUpdatePlaylist = () => {
    const trackOrder = workingTracks.map(({ id }) => id)
    const playlistDef = {
      ...defaultPlaylistInfo,
      slug: workingPlaylistSlug,
      title: workingPlaylistTitle,
      perTrackPlayLimit: workingPerTrackPlayLimit,
      trackOrder,
      // TODO: wordCount, timedWordCount?!
    }
    const tracksToAdd = isNew && currTrackInfo ? [currTrackInfo] : []
    dispatch(updatePlaylist({ matchSlug, playlistDef, tracksToAdd }))
    setIsDirty(false)
    onClose(true)
  }
  const onTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWorkingPlaylistTitle(event.target.value)
    setIsDirty(true)
  }
  const onPerTrackPlayLimitChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newLimit = parseInt(event.target.value)
    const workingLimit = isNaN(newLimit) || newLimit < 0 ? -1 : newLimit
    setWorkingPerTrackPlayLimit(workingLimit)
    setIsDirty(true)
  }
  const titleRef = createRef<HTMLInputElement>()
  React.useEffect(() => {
    const titleElem = titleRef.current
    if (!isDirty && titleElem) {
      titleElem.select()
    }
  })
  const setList = (tracks: PlaylistTrack[]) => {
    setWorkingTracks(tracks)
    if (isTracksInited) {
      setIsDirty(true)
    } else {
      setIsTracksInited(true)
    }
  }
  return (
    <div className="modalContainer playlistModal" ref={onModalContainerRef} tabIndex={0}>
      <CloseIcon onClose={onClose} />
      <div className="modalHeading">{`${isNew ? 'New Set' : 'Set Info'}`}</div>
      <div className="info">
        <div className="left">
          <div>
            <label htmlFor="playlistSlug">Blaster ID: </label>
            <input id="playlistSlug" type="text" readOnly value={workingPlaylistSlug} />
          </div>
          <div>
            <label htmlFor="matchTitle">Match: </label>
            <input id="matchTitle" type="text" readOnly value={matchTitle} />
          </div>
        </div>
        <div className="right">
          <div>
            <TextInputWithLabel
              id="playlistTitle"
              label="Title"
              onRef={titleRef}
              value={workingPlaylistTitle}
              onBlur={onTitleChange}
            />
          </div>
          <div>
            <NumberInputWithLabel
              id="maxPlaysPerTrack"
              label="Max Plays Per Track"
              value={workingPerTrackPlayLimit >= 0 ? workingPerTrackPlayLimit : ''}
              onBlur={onPerTrackPlayLimitChange}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="tracksContainer">
          <div className="working">
            <PlaylistSortRadio
              onSortTracksBy={sortWorkingTracks}
              radioId="to"
              sortBy={workingSortBy}
            />
            <ReactSortable
              className="playlistDisplay"
              animation="150"
              list={workingTracks}
              setList={setList}
            >
              {workingTracks.map(addTrack)}
            </ReactSortable>
            <div>
              <span className="help">(drag for custom order)</span>
            </div>
          </div>
          <div className="chooseFrom">
            <PlaylistSortRadio
              onSortTracksBy={sortChooseFromTracks}
              radioId="from"
              sortBy={chooseFromSortBy}
            />
            <div className="playlistDisplay">{tracksToChooseFrom.map(addTrack)}</div>
            <SelectWithLabel
              label="Choose from"
              itemArray={playlistItems}
              onChange={onSwitchSelectFrom}
              value={selectFromPlaylistSlug}
            />
          </div>
        </div>
      </div>
      <div>
        <button disabled={!isNew && !isDirty} onClick={onAddOrUpdatePlaylist}>
          {isNew ? 'Create' : 'Update'}
        </button>
      </div>
    </div>
  )
}

export default PlaylistModal
