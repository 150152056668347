import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight, pathOr } from 'ramda'

import {
  GuestPlayer,
  MatchInfo,
  MatchStatus,
  MatchStatusMap,
  MinimalInviteInfo,
  UserMatchStatusMap,
} from '../types'
import { defaultPlayerScore } from '../constants/constants'

export interface MatchStatusState {
  [key: string]: MatchStatusMap
}
const initialState: MatchStatusState = {}

type MatchId = {
  matchOwner: string
  matchSlug: string
}
type InviteId = MatchId & {
  inviteSlug: string
}
type MatchStatusPayload = MatchId & {
  matchStatus: MatchStatus
}
type MatchInfoPayload = MatchId & {
  matchInfo: MatchInfo
}
type MatchInvitesPayload = MatchId & {
  matchInvites: MinimalInviteInfo[]
}
type MatchInvitePayload = MatchId & {
  matchInvite: MinimalInviteInfo
}
type MatchPlayersPayload = MatchId & {
  players: UserMatchStatusMap
}
type MatchPlayerPayload = MatchId & {
  player: GuestPlayer
}
type MatchLeaderboardPayload = MatchId & {
  leaderboard: string[]
}
const matchStatusSlice = createSlice({
  name: 'matchStatus',
  initialState,
  reducers: {
    updateMatchStatus: (state, action: PayloadAction<MatchStatusPayload>) => {
      const { matchOwner, matchSlug, matchStatus } = action.payload
      const newStatus = {
        [matchOwner]: {
          [matchSlug]: matchStatus,
        },
      }
      const result = mergeDeepRight(state, newStatus)
      return result
    },
    updateMatchInfo: (state, action: PayloadAction<MatchInfoPayload>) => {
      const { matchOwner, matchSlug, matchInfo } = action.payload
      const newStatus = {
        [matchOwner]: {
          [matchSlug]: {
            info: matchInfo,
          },
        },
      }
      const result = mergeDeepRight(state, newStatus)
      return result
    },
    updateMatchInvites: (state, action: PayloadAction<MatchInvitesPayload>) => {
      const { matchOwner, matchSlug, matchInvites } = action.payload
      const newStatus = {
        [matchOwner]: {
          [matchSlug]: {
            invites: matchInvites,
          },
        },
      }
      const result = mergeDeepRight(state, newStatus)
      return result
    },
    addOrUpdateMatchInvite: (state, action: PayloadAction<MatchInvitePayload>) => {
      const { matchOwner, matchSlug, matchInvite } = action.payload
      const { slug: inviteSlug } = matchInvite
      const newStatus = {
        [matchOwner]: {
          [matchSlug]: {
            invites: {
              [inviteSlug]: matchInvite,
            },
          },
        },
      }
      const result = mergeDeepRight(state, newStatus)
      return result
    },
    removeMatchInvite: (state, action: PayloadAction<InviteId>) => {
      const { matchOwner, matchSlug, inviteSlug } = action.payload
      delete state[matchOwner][matchSlug].invites[inviteSlug]
    },
    deletePlayer: (state, action: PayloadAction<MatchPlayerPayload>) => {
      const {
        matchOwner,
        matchSlug,
        player: { slug },
      } = action.payload
      delete state[matchOwner][matchSlug].players[slug]
    },
    updateMatchPlayers: (state, action: PayloadAction<MatchPlayersPayload>) => {
      const { matchOwner, matchSlug, players } = action.payload
      const newStatus = {
        [matchOwner]: {
          [matchSlug]: {
            players,
          },
        },
      }
      const result = mergeDeepRight(state, newStatus)
      return result
    },
    updateMatchPlayerName: (state, action: PayloadAction<MatchPlayerPayload>) => {
      const {
        matchOwner,
        matchSlug,
        player: { slug, name },
      } = action.payload

      const playerScore = pathOr(
        defaultPlayerScore(),
        [matchOwner, matchSlug, 'players', matchOwner, slug],
        state
      )
      // NOTE: this pattern isn't type safe TODO: newer (or eliminate) ramda?
      const newStatus = {
        [matchOwner]: {
          [matchSlug]: {
            players: {
              [matchOwner]: {
                [slug]: {
                  ...playerScore,
                  name,
                },
              },
            },
          },
        },
      }
      const result = mergeDeepRight(state, newStatus)
      return result
    },
    updateLeaderboard: (state, action: PayloadAction<MatchLeaderboardPayload>) => {
      const { matchOwner, matchSlug, leaderboard } = action.payload
      const newStatus = {
        [matchOwner]: {
          [matchSlug]: {
            leaderboard,
          },
        },
      }
      const result = mergeDeepRight(state, newStatus)
      return result
    },
    updateTrackScore: (state, action) => {
      const {
        username,
        player,
        matchSlug: compoundMatchSlug,
        trackSlug,
        playStatus,
      } = action.payload
      const [matchOwner, matchSlug] = compoundMatchSlug.split('/') // TODO: fix
      const updatedPeer = {
        [matchOwner]: {
          [matchSlug]: {
            players: {
              [username]: {
                [player]: {
                  trackScores: {
                    [trackSlug]: playStatus,
                  },
                },
              },
            },
          },
        },
      }
      const result = mergeDeepRight(state, updatedPeer)
      return result
    },
    updatePlaylistScore: (state, action) => {
      const {
        username,
        player,
        matchSlug: compoundMatchSlug,
        playlistSlug,
        playStatus,
      } = action.payload
      const [matchOwner, matchSlug] = compoundMatchSlug.split('/') // TODO: fix
      const updatedPeer = {
        [matchOwner]: {
          [matchSlug]: {
            players: {
              [username]: {
                [player]: {
                  playlistScores: {
                    [playlistSlug]: playStatus,
                  },
                },
              },
            },
          },
        },
      }
      const result = mergeDeepRight(state, updatedPeer)
      return result
    },
    updateMatchScore: (state, action) => {
      const { matchSlug: compoundMatchSlug, username, player, playStatus } = action.payload
      const [matchOwner, matchSlug] = compoundMatchSlug.split('/') // TODO: fix
      const updatedPeer = {
        [matchOwner]: {
          [matchSlug]: {
            players: {
              [username]: {
                [player]: {
                  matchScore: playStatus,
                },
              },
            },
          },
        },
      }
      const result = mergeDeepRight(state, updatedPeer)
      return result
    },
  },
})

export default matchStatusSlice
