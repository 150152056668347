import { pathOr } from 'ramda'

import { BLASTER_URL_PREFIX } from '../constants/constants'
import { RootState } from '../reducers'
import { PlaylistInfo, TrackInfo, TrackToPlaylistsMap } from '../types'
import { getTrackPoints } from '../util/score-utils'
import { getTrackArtistAndTitle } from '../util/track-utils'
import { selectCurrentTrackInfo } from './current-play-selectors'
import { selectIsInLocalTracks } from './local-authoring-selectors'
import { selectMatchInfo, selectPlaylistMap } from './match-selectors'
import { selectCurrentUsername, selectScoreVersion } from './session-selectors'

const selectBlasterPeer = (username: string) => (state: RootState) => state.blasterPeers[username]

const selectTrackInfo =
  (username: string, trackSlug: string) =>
  (state: RootState): TrackInfo | null =>
    pathOr(null, [username, 'tracks', trackSlug], state.blasterPeers)

const selectTrackScore =
  ({ duration, timedWordCount, emptyAirtime }: TrackInfo) =>
  (state: RootState) => {
    const scoreVersion = selectScoreVersion(state)
    const scoreDuration = scoreVersion === 1 ? duration : duration - emptyAirtime
    return getTrackPoints(timedWordCount, scoreDuration)
  }

const selectIsCurrentTrackAdoptable = (state: RootState) => {
  const currTrackInfo = selectCurrentTrackInfo(state)
  const { slug, owner: currentTrackOwner } = currTrackInfo
  const username = selectCurrentUsername(state)
  const ownedTrackInfo = selectTrackInfo(username, slug)(state)
  const { owner: ownedTrackOwner } = ownedTrackInfo || { owner: null }
  const localTrackInState = selectIsInLocalTracks(slug)(state)
  return !localTrackInState && ownedTrackOwner && currentTrackOwner !== ownedTrackOwner
}

const selectCurrentUser = (state: RootState) => {
  const username = selectCurrentUsername(state)
  return state.blasterPeers[username]
}

const selectOwnedMatchSlug = (state: RootState) => {
  const username = selectCurrentUsername(state)
  return pathOr('', ['blasterPeers', username, 'ownedMatchSlug'], state)
}

const selectIsOwnedMatchSlug = (compoundMatchSlug: string) => (state: RootState) => {
  const ownedMatchSlug = selectOwnedMatchSlug(state)
  return ownedMatchSlug === compoundMatchSlug
}

// const selectUserTopScoreForTrack = (
//   state: RootState,
//   username: string,
//   matchSlug: string,
//   songSlug: string
// ) => {
//   const peer = selectBlasterPeer(username)(state)
//   const currMatchStatus = peer.matchStatus[matchSlug]
//   const trackScore = currMatchStatus && currMatchStatus.trackScores[songSlug]
//   return trackScore ? trackScore.topScore : 0
// }

type SelectedTrackInfo = {
  matchSlug: string
  trackInfo: TrackInfo
  playlistInfo: PlaylistInfo
}
const selectTrackFromPath = (state: RootState, trackPath: string): SelectedTrackInfo | null => {
  const pathParts = trackPath.split('/').filter((part) => !!part)
  if (pathParts.length < 5) {
    console.log('incomplete path', trackPath)
    return null
  }
  const [prefix, playlistOwner, matchSlug, playlistSlug, trackSlug] = pathParts
  if (prefix !== BLASTER_URL_PREFIX) {
    console.log('unsupported prefix', prefix) // TODO: think about multi-prefix support...
    return null
  }
  const { playlists: playlistMap = {} } = selectMatchInfo(playlistOwner, matchSlug)(state)
  const { tracks } = state.blasterPeers[playlistOwner]
  const trackInfo = tracks[trackSlug]
  if (trackInfo) {
    const playlist = playlistMap[playlistSlug]
    if (playlist) {
      const containsTrack = playlist.trackOrder?.includes(trackSlug)
      if (containsTrack) {
        const { title, artist } = getTrackArtistAndTitle(trackInfo)
        const compoundMatchSlug = `${playlistOwner}/${matchSlug}`
        return {
          trackInfo: {
            ...trackInfo,
            artist,
            title,
          },
          playlistInfo: playlist,
          // playlistOwner,
          // playlistSlug,
          // playlistTitle: playlist.title,
          matchSlug: compoundMatchSlug,
        }
      }
      console.log(
        `found ${trackSlug} for ${playlistOwner} but not in requested playlist (${playlistSlug})`
      )
      return null
    }
  }
  console.log(`track ${trackSlug} not in ${playlistOwner}'s tracks`)
  return null
}

// TODO: compute and maintain in state? or at least make sure selector is memoized
const selectTrackToPlaylistsMap =
  (username: string) =>
  (state: RootState): TrackToPlaylistsMap => {
    const { playlists: playlistMap = {} } = selectMatchInfo(username, '')(state)
    const trackToPlaylistsMap: { [key: string]: string[] } = {}
    Object.keys(playlistMap).forEach((playlistSlug) => {
      const { trackOrder = [] } = playlistMap[playlistSlug]
      trackOrder.forEach((trackSlug) => {
        const currTrackPlaylists = trackToPlaylistsMap[trackSlug] || []
        trackToPlaylistsMap[trackSlug] = [...currTrackPlaylists, playlistSlug]
      })
    })
    return trackToPlaylistsMap
  }

const selectReadyPeers = (state: RootState) => {
  const peerNames = Object.keys(state.blasterPeers)
  const readyPeers = peerNames.filter((name: string) => {
    const blasterPeer = state.blasterPeers[name]
    return blasterPeer && blasterPeer.tracksInitialized
  })
  return readyPeers
}

const selectOwnedPlaylistMap = (username: string) => (state: RootState) => {
  const { ownedMatchSlug } = state.blasterPeers[username]
  const playlists = ownedMatchSlug ? selectPlaylistMap(ownedMatchSlug)(state) : {}
  return playlists
}

const selectUserTracksInfo = (username: string) => (state: RootState) => {
  return state.blasterPeers[username].tracks
}

const selectActiveMatchSlug = (state: RootState, username: string) => {
  return `${username}/${state.blasterPeers[username].activeMatchSlug}`
}

const selectFollowReceived = (state: RootState, username: string) => {
  const { broadcastTo, syncWith } = state.blasterPeers[username]
  let msg
  if (broadcastTo) {
    msg = syncWith ? "I'm following you, too!" : 'I am following you.'
  } else {
    msg = 'I am no longer following you.'
  }
  return msg
}

const selectFollowSent = (state: RootState, username: string) => {
  const { broadcastTo, syncWith } = state.blasterPeers[username]
  let msg
  if (syncWith) {
    msg = broadcastTo ? "I'm following you, too!" : 'I am following you.'
  } else {
    msg = 'I am no longer following you.'
  }

  return msg
}

export {
  selectActiveMatchSlug,
  selectReadyPeers,
  selectBlasterPeer,
  selectCurrentUser,
  selectFollowReceived,
  selectFollowSent,
  selectIsCurrentTrackAdoptable,
  selectIsOwnedMatchSlug,
  selectOwnedMatchSlug,
  selectOwnedPlaylistMap,
  selectTrackFromPath,
  selectTrackInfo,
  selectTrackToPlaylistsMap,
  // selectUserTopScoreForTrack,
  selectTrackScore,
  selectUserTracksInfo,
}
