import React from 'react'
import { SortBy } from '../modals/PlaylistModal'

type Props = {
  radioId: string
  sortBy: SortBy
  onSortTracksBy: (sortTracksBy: SortBy) => void
}
const PlaylistSortRadio = ({ radioId, onSortTracksBy, sortBy }: Props) => {
  return (
    <div className="sortRadio">
      <label className="radio">
        <input
          type="radio"
          name={radioId}
          checked={sortBy === SortBy.custom}
          onChange={() => onSortTracksBy(SortBy.custom)}
        />
        Custom
      </label>
      <label className="radio">
        <input
          type="radio"
          name={radioId}
          checked={sortBy === SortBy.title}
          onChange={() => onSortTracksBy(SortBy.title)}
        />
        Title
      </label>
      <label className="radio">
        <input
          type="radio"
          name={radioId}
          checked={sortBy === SortBy.artist}
          onChange={() => onSortTracksBy(SortBy.artist)}
        />
        Artist
      </label>
      <label className="radio">
        <input
          type="radio"
          name={radioId}
          checked={sortBy === SortBy.points}
          onChange={() => onSortTracksBy(SortBy.points)}
        />
        Points
      </label>
    </div>
  )
}

export default PlaylistSortRadio
