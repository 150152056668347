import Util from '../util'
import { Line, Section, Word } from '../../types'

class Buffer {
  buffer: string[]
  index: number
  separator: string
  constructor(separator: string) {
    // TODO: add size initializer?
    this.buffer = []
    this.index = 0
    this.separator = separator ? separator : ''
  }
  append(str: string) {
    this.buffer[this.index] = str
    this.index++
  }

  toString() {
    return this.buffer.join(this.separator)
  }
}
class LyricPrintVisitor {
  isLRC: boolean
  isPrettyPrint: boolean
  _lineBuffer: Buffer | null
  _wordBuffer: Buffer | null
  constructor(isLRC: boolean, isPrettyPrint = false) {
    this.isLRC = isLRC
    this.isPrettyPrint = isPrettyPrint
    this._lineBuffer = null
    this._wordBuffer = null
  }
  get wordBuffer(): Buffer {
    if (!this._wordBuffer) {
      throw new Error('wordBuffer not initialized')
    }
    return this._wordBuffer
  }
  get lineBuffer(): Buffer {
    if (!this._lineBuffer) {
      throw new Error('lineBuffer not initialized')
    }
    return this._lineBuffer
  }
  toString() {
    return this.lineBuffer.toString()
  }

  startTrack() {
    this._lineBuffer = new Buffer('\n')
  }

  endTrack() {}

  startSection(section: Section) {
    let text

    if (this.isLRC) {
      if (section.time === 0.0 && section.lines.length > 0) {
        section.time = section.lines[0].words[0].time
      }
      text = `[${Util.secondsToClock(section.time || 0)}][${section.label}]`
    } else {
      text = `[${section.label}]`
    }
    this.lineBuffer.append(text)
  }

  endSection() {
    if (this.isPrettyPrint) {
      this.lineBuffer.append('')
    }
  }

  startLine() {
    this._wordBuffer = new Buffer(' ')
  }

  endLine() {
    this.lineBuffer.append(this.wordBuffer.toString())
  }

  visitWord(word: Word, line: Line, section: Section) {
    let text

    if (this.isLRC) {
      text =
        (word.index === 0 ? '[' : '<') +
        Util.secondsToClock(word.time || 0) +
        (word.index === 0 ? ']' : '>') +
        word.label
    } else {
      text = word.label
    }

    this.wordBuffer.append(text)
  }
}

export default LyricPrintVisitor
