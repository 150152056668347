import { createAsyncThunk } from '@reduxjs/toolkit'

import { MINIMUM_SECTION_SWITCH_DURATION_SECS } from '../constants/constants'
import { RootState } from '../reducers'
import { Section } from '../types'
import { selectViewSettings } from '../selectors/settings-selectors'
import Gamer from '../services/Gamer'

const sectionsToAnimate = (sections: Section[]): Section[] => {
  return sections.filter((section: Section, index: number) => {
    const prevSection = index > 0 ? sections[index - 1] : null
    if (!prevSection || prevSection.lines.length === 0) {
      return false
    }
    const prevSectionStartTime = prevSection.referenceTime || 0
    const sectionStartTime = section.referenceTime || 0
    const prevSectionDuration = sectionStartTime - prevSectionStartTime
    return prevSectionDuration > MINIMUM_SECTION_SWITCH_DURATION_SECS
  }, [])
}
const scheduleSectionAnimations = createAsyncThunk<void, Gamer, { state: RootState }>(
  'playAction/scheduleSectionAnimations',
  (gamer, { dispatch, getState }) => {
    const { gamerIndex, vizBuilder } = gamer
    const { isShowSectionAnimation: isTurningOnAnimation } = selectViewSettings(gamerIndex)(
      getState()
    )
    const turnOn = (section: Section) => {
      section.elem?.classList.add('sectionAnimation')
    }
    const turnOff = (section: Section) => {
      section.elem?.classList.remove('sectionAnimation')
    }
    const nonEmptySectionsToSchedule: Section[] = sectionsToAnimate(vizBuilder.sections)
    if (!isTurningOnAnimation) {
      nonEmptySectionsToSchedule.forEach((section: Section) => {
        gamer.removePartEvent(section)
      })
      return
    }
    nonEmptySectionsToSchedule.forEach((section: Section) => {
      // console.log('Scheduling animation for section:', section.index, section.label)
      gamer.setPartEvent({
        eventCallback: () => {
          turnOn(section)
          setTimeout(() => {
            turnOff(section)
          }, 1000 * (MINIMUM_SECTION_SWITCH_DURATION_SECS + 1))
        },
        part: section,
        useReferenceTime: true,
        deltaTime: MINIMUM_SECTION_SWITCH_DURATION_SECS,
      })
    })
  }
)

export default scheduleSectionAnimations
