import cx from 'classnames'
import React, { ChangeEvent, useEffect } from 'react'
import { ReactSortable } from 'react-sortablejs'

import themes from '../../themes'
import {
  API_PATH_SPOTIFY_LOGIN,
  DEFAULT_OWNED_MATCH_TITLE,
  MAX_USER_ALIASES,
} from '../../../constants/constants'
import { initializeAuthoring } from '../../../actions/authoring/initialize-authoring'
import { updateUserMatchOrder } from '../../../actions/social/leaderboard-actions'
import { switchUserAlias } from '../../../actions/social/update-username'
import { initSpotifyPlayer } from '../../../actions/track-loading/spotify'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import modalsSlice from '../../../reducers/modalsSlice'
import { selectOwnedMatchSlug } from '../../../selectors/blaster-peer-selectors'
import { selectUserMatchesInfo } from '../../../selectors/match-selectors'
import {
  selectHasSpotifyToken,
  selectIsSpotifyConnected,
  selectPrefs,
  selectUserInfo,
} from '../../../selectors/session-selectors'
import userManager from '../../../services/UserManager'
import UserManager from '../../../services/UserManager'
import { UserMatchInfo } from '../../../types'
import { onModalContainerRef } from '../../../util/scrolling'
import CloseIcon from '../../widgets/CloseIcon'
import {
  ImageWithLabel,
  SelectWithLabel,
  TextInputWithLabel,
} from '../../widgets/TextInputWithLabel'
import ModalHeading from '../ModalHeading'
import ModalBackdrop from '../ModalBackdrop'
import NameChangeModal from './NameChangeModal'
import { UserMatchInvites } from './UserMatchInvites'

const modesRadio = [
  { name: 'Viz', key: 'viz' },
  { name: 'Spell', key: 'spell' },
  { name: 'Blast', key: 'play' },
  { name: 'Sticky', key: 'last' },
]
const audienceRadio = [
  { name: 'Pub', key: 'pub' },
  { name: 'Couch', key: 'couch' },
  { name: 'Solo', key: 'solo' },
  { name: 'Prod', key: 'prod' },
]
const scoringRadio = [
  { name: 'New', key: 2 },
  { name: 'Old', key: 1 },
]

type Props = {
  manager: typeof UserManager
  onClose: () => void
  onSwitchScoreVersion: (scoreVersion: number) => void
  onSwitchTheme: (themeIndex: number) => void
  onSwitchDefaultMode: (whichModeAudience: string) => void
  onSwitchAudience: (whichAudience: string) => void
  onSetLatencyMillis: (latencyMillis: number, isUseLatency: boolean) => void
}
const UserSettingsModal = ({
  manager,
  onClose,
  onSwitchScoreVersion,
  onSwitchTheme,
  onSwitchDefaultMode,
  onSwitchAudience,
  onSetLatencyMillis,
}: Props) => {
  const dispatch = useAppDispatch()
  const { currUsername, currUserAliasIndex, userAliases } = useAppSelector(selectUserInfo)
  const hasSpotifyToken = useAppSelector(selectHasSpotifyToken)
  const isSpotifyConnected = useAppSelector(selectIsSpotifyConnected)
  const currentUserMatches: UserMatchInfo[] = useAppSelector(selectUserMatchesInfo)
  const ownedMatchSlug = useAppSelector(selectOwnedMatchSlug)
  const [userMatches, setUserMatches] = React.useState<UserMatchInfo[]>([])
  useEffect(() => {
    setUserMatches(currentUserMatches)
  }, [currentUserMatches])
  const [isShowNameChangeModal, setisShowNameChangeModal] = React.useState(false)
  const [isMatchOrderDirty, setIsMatchOrderDirty] = React.useState(false)
  useEffect(() => {
    if (isMatchOrderDirty) {
      setIsMatchOrderDirty(false)
      dispatch(updateUserMatchOrder(userMatches))
    }
  }, [userMatches, isMatchOrderDirty, dispatch])

  const {
    theme: themeIndex,
    defaultMode,
    audience,
    latencyMillis,
    isUseLatency,
  } = useAppSelector(selectPrefs)

  const _addMatch = (matchInfo: UserMatchInfo, matchIndex: number) => {
    const { id: compoundMatchSlug, matchOwner, title, isActive } = matchInfo
    const isChecked = isActive
    const checkboxId = `match-${compoundMatchSlug}`
    const onChecked = (event: ChangeEvent<HTMLInputElement>) => {
      const newIsChecked = event.currentTarget.checked
      const newMatches = [...userMatches]
      newMatches[matchIndex].isActive = newIsChecked
      dispatch(updateUserMatchOrder(newMatches))
    }
    const openMatchInfo = () => {
      dispatch(modalsSlice.actions.toggleMatchInfo(compoundMatchSlug))
    }
    return (
      <div
        key={compoundMatchSlug}
        data-id={compoundMatchSlug}
        className="sortable-list-item handle match"
      >
        <input type="checkbox" id={checkboxId} onChange={onChecked} checked={isChecked} />
        <div className="handle right" onClick={openMatchInfo}>
          <div className="title">{title}</div>
          <div className="owner">{matchOwner}</div>
        </div>
      </div>
    )
  }
  const onMatchSort = () => {
    setIsMatchOrderDirty(true)
  }

  const enableMyStuff = () => {
    dispatch(initializeAuthoring())
    const matchItem: UserMatchInfo = {
      id: ownedMatchSlug,
      matchSlug: ownedMatchSlug,
      inviteKey: '',
      matchOwner: currUsername,
      title: DEFAULT_OWNED_MATCH_TITLE,
      isActive: true,
    }
    setUserMatches([...userMatches, matchItem])
  }

  const getNameChangeModal = () => {
    if (!isShowNameChangeModal) {
      return false
    }
    const onClose = () => {
      setisShowNameChangeModal(false)
    }
    return <NameChangeModal onClose={onClose} />
  }
  const getIdentitySection = () => {
    const aliasesMenu =
      userAliases.length === 1 ? null : userAliases.map((alias) => ({ value: alias, name: alias }))
    const currAlias = currUserAliasIndex < userAliases.length ? userAliases[currUserAliasIndex] : ''
    const onSwitchAlias = (event: ChangeEvent<HTMLInputElement>) => {
      const chosenUserName = event.target.value
      dispatch(switchUserAlias(chosenUserName)).then((isSuccess) => {
        if (isSuccess) {
          manager.logout()
        }
      })
    }
    const onNewAlias = () => {
      setisShowNameChangeModal(true)
    }
    const isNewAliasButtonEnabled = userAliases.length < MAX_USER_ALIASES
    return (
      <div className="identitySection">
        <div className="heading">Identity</div>
        <div className="sectionBody">
          <div className="nameRow">
            {aliasesMenu ? (
              <SelectWithLabel
                id="blasterName"
                label="Blaster Name"
                itemArray={aliasesMenu}
                value={currAlias}
                onChange={onSwitchAlias}
              />
            ) : (
              <TextInputWithLabel
                id="blasterName"
                label="Blaster Name"
                value={currAlias}
                isReadOnly
              />
            )}
            {isNewAliasButtonEnabled && (
              <button className="chatNewTrackButton first" onClick={onNewAlias}>
                New...
              </button>
            )}
          </div>
          <TextInputWithLabel id="blasterEmail" label="Email" value={manager.email} isReadOnly />
          <ImageWithLabel src={manager.picture} label="Badge" />
        </div>
      </div>
    )
  }
  const getPrefsSection = () => {
    const setLatency = (event: ChangeEvent<HTMLInputElement>) => {
      onSetLatencyMillis(parseInt(event.target.value), isUseLatency)
    }
    const setIsUseLatency = (event: ChangeEvent<HTMLInputElement>) => {
      onSetLatencyMillis(latencyMillis, event.target.checked)
    }
    const onLoginSpotify = () => {
      window.open(`${API_PATH_SPOTIFY_LOGIN}/${currUsername}`, '_blank')
    }
    const onConnectSpotify = () => {
      dispatch(initSpotifyPlayer(userManager.accessToken))
    }
    const spotifyPrompt = isSpotifyConnected
      ? 'connected!'
      : hasSpotifyToken
      ? 'connecting, pls select LyricBlaster device in Spotify...'
      : 'not connected'
    const effectiveMode = defaultMode.split('-')[0]
    const latencyDisplayClass = cx('help', { disabled: !isUseLatency })
    return (
      <div>
        <div className="heading">Preferences</div>
        <div className="setting">
          <label>Appearance: </label>
          {themes.map(({ name }, i) => (
            <label className="radio" key={i}>
              <input
                type="radio"
                name="theme"
                value={i}
                checked={themeIndex === i}
                onChange={() => onSwitchTheme(i)}
              />
              {name}
            </label>
          ))}
        </div>
        <div className="setting">
          <label>Game Mode: </label>
          {audienceRadio.map(({ name, key }, i) => (
            <label className="radio" key={key}>
              <input
                type="radio"
                name="audience"
                value={i}
                checked={audience === key}
                onChange={() => onSwitchAudience(key)}
              />
              {name}
            </label>
          ))}
        </div>
        <div className="setting">
          <label>Tracks Open In: </label>
          {modesRadio.map(({ name, key }, i) => (
            <label className="radio" key={key}>
              <input
                type="radio"
                name="defaultMode"
                value={i}
                checked={effectiveMode === key}
                onChange={() => onSwitchDefaultMode(key)}
              />
              {name}
            </label>
          ))}
        </div>
        <div className="setting">
          <label>Scoring:</label>
          {scoringRadio.map(({ name, key }, i) => (
            <label className="radio" key={`score-${key}`}>
              <input
                type="radio"
                name="scoring"
                value={i}
                checked={manager.scoreVersion === key}
                onChange={() => onSwitchScoreVersion(key)}
              />
              {name}
            </label>
          ))}
        </div>
        <div className="setting">
          <label>Audio Latency: </label>
          <span className={latencyDisplayClass}>{latencyMillis} ms</span>
          <input
            type="range"
            step="5"
            min="0"
            max="1000"
            defaultValue={latencyMillis}
            onChange={setLatency}
            onInput={setLatency}
            disabled={!isUseLatency}
          />
          <input type="checkbox" onChange={setIsUseLatency} defaultChecked={isUseLatency} />
        </div>
        <div className="setting">
          <label>Spotify:</label>
          <span className="help">{spotifyPrompt}</span>
          {!hasSpotifyToken && (
            <>
              <button className="chatNewTrackButton first" onClick={onConnectSpotify}>
                Connect
              </button>
              <button className="chatNewTrackButton" onClick={onLoginSpotify}>
                Authenticate
              </button>
            </>
          )}
        </div>
      </div>
    )
  }
  const getMatchesSection = () => {
    return (
      <div>
        <div className="heading">My Matches</div>
        <div className="help">(check/uncheck to hide/show, drag to re-order)</div>
        {!ownedMatchSlug && (
          <div>
            <button className="enableMyStuffButton" onClick={enableMyStuff}>
              Enable {DEFAULT_OWNED_MATCH_TITLE}
            </button>
          </div>
        )}
        <ReactSortable
          className="playlistDisplay"
          animation="150"
          list={userMatches}
          setList={setUserMatches}
          onSort={onMatchSort}
        >
          {userMatches.map(_addMatch)}
        </ReactSortable>
      </div>
    )
  }
  return (
    <ModalBackdrop isTop>
      <div className="modalContainer userSettingsModal" ref={onModalContainerRef}>
        <CloseIcon onClose={onClose} />
        <ModalHeading title="User Settings" />
        <div>{getPrefsSection()}</div>
        <div>{getIdentitySection()}</div>
        <div>
          <UserMatchInvites onClose={onClose} />
        </div>
        <div>{getMatchesSection()}</div>
        {getNameChangeModal()}
      </div>
    </ModalBackdrop>
  )
}

export default UserSettingsModal
