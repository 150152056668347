import cx from 'classnames'
import React, { createRef, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { BLASTER_URL_PREFIX } from '../../../constants/constants'
import { useAppSelector } from '../../../hooks'
import { selectCurrentTrackSlug } from '../../../selectors/current-play-selectors'
import { selectTrackStats } from '../../../selectors/leaderboard-selectors'
import { selectPlaylistTracks } from '../../../selectors/match-selectors'
import {
  selectAudienceFlags,
  selectCurrentBlaster,
  selectCurrentMatchSlug,
  selectCurrentUsername,
} from '../../../selectors/session-selectors'
import keyBindings from '../../../services/KeyBindings'
import { TopScoreStat, TrackInfo } from '../../../types'
import { getTrackPoints } from '../../../util/score-utils'
import { getTrackArtistAndTitle } from '../../../util/track-utils'
import Util from '../../../util/util'

type Props = {
  playlistSlug: string
  scoreVersion: number
}
type TrackGridInfo = {
  isDisabled: boolean
  isCurrent: boolean
  trackInfo?: TrackInfo
}

const SQUARES_PER_ROW = 4

const TrackGallery = ({ playlistSlug, scoreVersion }: Props) => {
  const isPubMode = useAppSelector(selectAudienceFlags).isPub
  const compoundMatchSlug = useAppSelector(selectCurrentMatchSlug)
  const mappedBlasters = useAppSelector(selectCurrentBlaster)
  const username = useAppSelector(selectCurrentUsername)
  const [matchOwner, matchSlug] = compoundMatchSlug.split('/')
  const anyGuestIsPlaying = Object.keys(mappedBlasters).some((gamerId) => {
    return username === matchOwner && gamerId !== matchOwner
  })
  const { tracks, perTrackPlayLimit } = useAppSelector(
    selectPlaylistTracks(compoundMatchSlug, playlistSlug)
  )
  const matchTrackStats = useAppSelector(selectTrackStats({ matchOwner, matchSlug }))
  const tracksRef = createRef<HTMLDivElement>()
  const [selectedGridRow, setSelectedGridRow] = useState(0)
  const [selectedGridCol, setSelectedGridCol] = useState(0)
  const [trackGridInfo, setTrackGridInfo] = useState<TrackGridInfo[]>([])
  const currTrackSlug = useAppSelector(selectCurrentTrackSlug)
  const navCallback = useCallback(
    (horizStep: number, vertStep: number) => {
      const numSquares = trackGridInfo.length
      const numRows = numSquares / SQUARES_PER_ROW
      const selectedIndex = selectedGridRow * SQUARES_PER_ROW + selectedGridCol
      let gotoCol = selectedGridCol
      let gotoRow = selectedGridRow
      let gotoNode = null
      while (!gotoNode) {
        if (horizStep > 0) {
          if (gotoCol === SQUARES_PER_ROW - 1) {
            gotoCol = 0
            gotoRow = gotoRow === numRows - 1 ? 0 : gotoRow + 1
          } else {
            gotoCol++
          }
        } else if (horizStep < 0) {
          if (gotoCol === 0) {
            gotoCol = SQUARES_PER_ROW - 1
            gotoRow = gotoRow === 0 ? numRows - 1 : gotoRow - 1
          } else {
            gotoCol--
          }
        } else if (vertStep > 0) {
          if (gotoRow === numRows - 1) {
            gotoRow = 0
            gotoCol = gotoCol === SQUARES_PER_ROW - 1 ? 0 : gotoCol + 1
          } else {
            gotoRow++
          }
        } else if (vertStep < 0) {
          if (gotoRow === 0) {
            gotoRow = numRows - 1
            gotoCol = gotoCol === 0 ? SQUARES_PER_ROW - 1 : gotoCol - 1
          } else {
            gotoRow--
          }
        }
        const gotoIndex = gotoRow * SQUARES_PER_ROW + gotoCol
        const nthSquare = trackGridInfo[gotoIndex]
        if (nthSquare?.isDisabled) {
          if (gotoIndex === selectedIndex) {
            break // should never be back where we started
          }
        } else {
          gotoNode = tracksRef.current?.childNodes[gotoIndex] as HTMLAnchorElement
        }
      }
      setSelectedGridCol(gotoCol)
      setSelectedGridRow(gotoRow)
    },
    [selectedGridRow, selectedGridCol, tracksRef, trackGridInfo]
  )
  useEffect(() => {
    setSelectedGridCol(0)
    setSelectedGridRow(0)
  }, [playlistSlug])
  useEffect(() => {
    const currentTracksRef = tracksRef.current
    if (!currentTracksRef) {
      return
    }
    const gotoIndex = selectedGridRow * SQUARES_PER_ROW + selectedGridCol
    const initialIndex = trackGridInfo.findIndex(({ isCurrent, isDisabled }) => {
      return !isCurrent && !isDisabled
    })
    if (initialIndex >= 0) {
      const gotoNode = currentTracksRef.childNodes[gotoIndex] as HTMLElement
      gotoNode?.focus()
    }
  }, [selectedGridRow, selectedGridCol, trackGridInfo, tracksRef, playlistSlug])
  useEffect(() => {
    const onKeyDown = ({ keyCode }: KeyboardEvent) => {
      const vertStep = keyCode === 40 ? 1 : keyCode === 38 ? -1 : 0
      const horizStep = keyCode === 39 ? 1 : keyCode === 37 ? -1 : 0
      navCallback(horizStep, vertStep)
    }
    window.addEventListener('keydown', onKeyDown)
    keyBindings._navCallback = navCallback

    return () => {
      keyBindings._navCallback = null
      window.removeEventListener('keydown', onKeyDown)
    }
  })
  const isCheckPlaylimit = isPubMode && anyGuestIsPlaying && perTrackPlayLimit >= 0
  useEffect(() => {
    const trackGrid: TrackGridInfo[] = tracks.map((trackInfo) => {
      const { slug: trackSlug } = trackInfo
      const isCurrentTrack = trackSlug === currTrackSlug
      const { playerTopScores } = matchTrackStats[trackSlug] || {
        playerTopScores: [],
      }
      const currentBlasterHasPlayed = playerTopScores.some(({ playerId }) => {
        return playerId in mappedBlasters
      })
      const isDisabled =
        isCheckPlaylimit && (currentBlasterHasPlayed || playerTopScores.length >= perTrackPlayLimit)
      return {
        isDisabled: isDisabled || isCurrentTrack,
        isCurrent: isCurrentTrack,
        trackInfo,
      }
    })
    const numSquaresToAdd = (SQUARES_PER_ROW - (tracks.length % SQUARES_PER_ROW)) % SQUARES_PER_ROW
    for (let i = 0; i < numSquaresToAdd; i++) {
      trackGrid.push({ isDisabled: true, isCurrent: false })
    }
    setTrackGridInfo(trackGrid)
  }, [playlistSlug, perTrackPlayLimit, mappedBlasters, isCheckPlaylimit])
  // TODO: missing dependencies on tracks and matchTrackStats, but adding either of these causes infinite loop

  const getTracks = () => {
    const trackGrid = trackGridInfo.map(({ isCurrent, isDisabled, trackInfo }, index) => {
      if (!trackInfo) {
        return (
          <div key={`emptySquare-${index}`} className="emptySquare">
            <button className="blastButton left" tabIndex={-1} disabled />
          </div>
        )
      }
      const { slug: trackSlug, duration, wordCount, emptyAirtime = 0 } = trackInfo
      const { playerTopScores, username } = matchTrackStats[trackSlug] || {
        playerTopScores: [],
        username: '',
      }
      const { title, artist } = getTrackArtistAndTitle(trackInfo)
      const durationClockTime = Util.secondsToClock(duration, false, false)
      const scoreDuration = scoreVersion === 1 ? duration : duration - emptyAirtime
      const { pointsPerWord, maxPoints } = getTrackPoints(wordCount, scoreDuration)
      const pointsPerWordStr = duration > 0 ? pointsPerWord : '-'
      const pointsStr = duration > 0 ? maxPoints : '-'
      const durationStr = duration > 0 ? durationClockTime : '-'
      const toPath = `/${BLASTER_URL_PREFIX}/${compoundMatchSlug}/${playlistSlug}/${trackSlug}`
      const getTopScoreBadge = () => {
        return playerTopScores.map((topScoreStat: TopScoreStat) => {
          const {
            playStatus: { topScoreRank, topScore },
            playerId,
            playerName,
          } = topScoreStat
          const topScoreClassName = cx('topScore', { [topScoreRank]: true })
          return (
            <div key={`${username}-${playerId}`} className={topScoreClassName} title={playerName}>
              {topScore}
            </div>
          )
        })
      }
      const TrackDiv = () => (
        <div className="track">
          <div className="title">{title}</div>
          <div className="artist">{artist}</div>
          <div className="totalpoints">
            <div className="perfectscore">
              Perfect score<span className="points">{pointsStr}</span>
            </div>
            <div className="wordcountamount">
              Words<span className="wordcountnumber">{wordCount}</span>
            </div>
            <div className="pointspertrackamount">
              Time<span className="duration">{durationStr}</span>
            </div>
          </div>
          <div className="matchNodeInfo">
            <div className="score">
              <div className="pointInfo">
                <div className="totalduration">
                  Time<span className="duration">{durationStr}</span>
                </div>
                <div className="totalwordcount">
                  Words<span className="wordcountamount">{wordCount}</span>
                </div>
                <div className="totalpointspertrack">
                  Points/word<span className="pointrspertrack">{pointsPerWordStr}</span>
                </div>
              </div>
              <div className="prescore">
                <div className="previousscores">PREVIOUS SCORES</div>
                <div className="topScores">{getTopScoreBadge()}</div>
              </div>
            </div>
          </div>
        </div>
      )
      if (isDisabled) {
        const classname = cx('disabled', { current: isCurrent })
        return (
          <div key={trackSlug} className={classname}>
            <TrackDiv></TrackDiv>
          </div>
        )
      }
      return (
        <Link key={trackSlug} to={toPath}>
          <TrackDiv></TrackDiv>
        </Link>
      )
    })
    return trackGrid
  }
  return (
    <div className="trackGallery" ref={tracksRef}>
      {getTracks()}
    </div>
  )
}

export default TrackGallery
