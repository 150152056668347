import StartAudio from 'startaudiocontext'
import * as Tone from 'tone'

import { activateNextPane, togglePlay } from '../actions/play-actions'
import { switchActiveBlaster, updateMatchStatus } from '../actions/social/leaderboard-actions'
import { Pane } from '../constants/constants'
import { GREEDY_LINE_BREAK_REGEX } from '../constants/utility-constants'
import { AppDispatch } from '../reducers'
import currentPlaySlice from '../reducers/currentPlaySlice'
import sessionSlice from '../reducers/sessionSlice'
import settingsSlice from '../reducers/settingsSlice'
import { LocalTiming, ModelPart, ModeType, TrackInfo } from '../types'
import { isMobile } from '../util/track-utils'
import Util from '../util/util'
import { NullAudioPlayer, ToneAudioPlayer } from './AudioWrappers'
import Gamer from './Gamer'
import { LyricVizBuilder } from './LyricVizBuilder'

type GamerInfo = {
  gamerId: string
  isActive: boolean
  vizBuilder: LyricVizBuilder
  gamer: Gamer
}
export class TrackMixer {
  username: string
  _dispatch: AppDispatch | null
  isDragging: boolean
  _audioPlayers: { [key: string]: NullAudioPlayer }
  _player: NullAudioPlayer
  _volume: number
  _isReady: boolean
  _isPlaying: boolean
  _mode: ModeType
  isToneScheduled: boolean
  latencySeconds: number
  private _clock: HTMLInputElement | null
  private _scrubber: HTMLInputElement | null
  private _duration: HTMLInputElement | null
  _audioFileInput: HTMLInputElement | null
  _localAudioFileToUpload: File | undefined
  _imageFileInput: HTMLInputElement | null
  _localImageFileToUpload: File | undefined
  _gamers: GamerInfo[]
  synth: any

  constructor() {
    this.username = ''
    this._dispatch = null
    this.isDragging = false
    this._audioPlayers = {
      default: new NullAudioPlayer(),
    }
    this._player = this._audioPlayers.default

    this._volume = 5.0
    this._isReady = false
    this._isPlaying = false
    this._mode = 'play'
    this.isToneScheduled = false
    this.latencySeconds = 0
    this._audioFileInput = null
    this._imageFileInput = null
    this._clock = null
    this._scrubber = null
    this._duration = null
    this._gamers = []
  }

  get isPlayMode() {
    return this._mode === 'play'
  }
  get isVizMode() {
    return this._mode === 'viz'
  }
  get isEditMode() {
    return this._mode === 'edit'
  }
  get isSpellMode() {
    return this._mode === 'spell'
  }
  addGamer(gamerId: string) {
    const nextIndex = this._gamers.length
    const gamer = new Gamer(nextIndex, this)
    const gamerInfo = {
      isActive: false,
      gamerId,
      gamer,
      vizBuilder: new LyricVizBuilder(gamer),
    }
    this._gamers.push(gamerInfo)
    this.dispatch(currentPlaySlice.actions.setGamerId({ gamerIndex: nextIndex, gamerId }))
  }
  _toggleGamerActive(gamer: Gamer, timingLines: string[], localTiming?: LocalTiming) {
    setTimeout(() => {
      gamer.switchMode(this._mode, timingLines)

      if (localTiming) {
        // TODO: compatible with viz?
        const localTimingLines = localTiming.timing.split(GREEDY_LINE_BREAK_REGEX)
        gamer.scoreSeconds = localTiming.scoreSeconds
        gamer.importLRC({ lrcLines: localTimingLines, asReference: false, isLoad: true })
      }
      gamer._resetTarget()
    }, 200)
  }

  toggleGamerActive(
    gamerIndex: number,
    isActive: boolean,
    timingLines: string[],
    localTiming?: LocalTiming
  ) {
    const gamerInfo = this._gamers[gamerIndex]
    if (gamerInfo) {
      this.stop()
      gamerInfo.isActive = isActive
      if (isActive) {
        this._toggleGamerActive(
          gamerInfo.gamer,
          timingLines,
          gamerIndex === 0 ? localTiming : undefined
        )
      }
    }
  }
  init(dispatch: AppDispatch, latencyMillis: number, username: string) {
    this._dispatch = dispatch
    this.latencySeconds = latencyMillis / 1000
    this.username = username
    this._audioFileInput = document.getElementById('local-audio') as HTMLInputElement
    this._imageFileInput = document.getElementById('local-image') as HTMLInputElement
    this.synth = new Tone.Synth().toDestination()

    this.addGamer(username)
    this.addGamer('')
    this.addGamer('')

    // this.synth.triggerAttackRelease('C5', '16n')
    document.addEventListener('gesturestart', function (e) {
      e.preventDefault()
    })
  }

  initUI() {
    this.stop()
    this.rewindToStart()
    this.updateClock()

    if (!this.isToneScheduled) {
      this.isToneScheduled = true
      Tone.Transport.scheduleRepeat(() => this.updateClock(), '0.01s')
      Tone.Transport.scheduleRepeat(() => this.updateAnimation(), '0.2s')
      Tone.Transport.scheduleRepeat(() => this.updateMatchStatus(), '5.0s')
      if (isMobile()) {
        StartAudio(Tone.context, document.body, function () {
          // this.keyboard.buttons.activate();
        })
      }
    }
  }

  lyricTimingLoaded(timingLines: string[], localTiming?: LocalTiming) {
    this._gamers.forEach(({ gamer, isActive }, index) => {
      if (isActive) {
        this._toggleGamerActive(gamer, timingLines, localTiming)
      }
    })
  }

  toggleMasked(isRemoveMask: boolean) {
    this._gamers.forEach(({ gamer, isActive }, index) => {
      if (isActive) {
        gamer.toggleMasked(isRemoveMask)
      }
    })
    this.dispatch(settingsSlice.actions.setIsShowLyrics({ isShowLyrics: isRemoveMask }))
  }
  toggleLyricTextColor(newPerm: number) {
    const isOdd = newPerm % 2
    document.documentElement.style.setProperty('--color-txt-lyrics', isOdd ? 'black' : 'white')
  }
  get defaultGamer() {
    return this._gamers[0]?.gamer
  }

  switchMode(toMode: ModeType, referenceLrcLines: string[]) {
    const fromMode = this._mode
    this._mode = toMode
    this._gamers.forEach(({ gamer, isActive }) => {
      if (isActive) {
        gamer.switchMode(fromMode, referenceLrcLines) // TODO: why not just store isActive in gamer?
      }
    })
  }
  get dispatch(): AppDispatch {
    if (!this._dispatch) {
      throw new Error('dispatch not set')
    }
    return this._dispatch
  }
  get audioFileInput(): HTMLInputElement {
    if (!this._audioFileInput) {
      throw new Error('audioFileInput not set')
    }
    return this._audioFileInput
  }
  get imageFileInput(): HTMLInputElement {
    if (!this._imageFileInput) {
      throw new Error('imageFileInput not set')
    }
    return this._imageFileInput
  }
  set clock(clock: HTMLInputElement) {
    this._clock = clock
  }
  set duration(duration: HTMLInputElement) {
    this._duration = duration
  }
  set scrubber(scoreClock: HTMLInputElement) {
    this._scrubber = scoreClock
  }

  get player(): NullAudioPlayer {
    return this._player
  }
  // set player(playerKey: string) {
  //   const newPlayer = this._audioPlayers[playerKey]
  //   this._player = newPlayer || this._audioPlayers['default']
  // }
  get volume() {
    return this._volume
  }
  get clockSeconds() {
    return Math.max(0, Tone.getTransport().seconds)
  }
  set clockSeconds(seconds: number) {
    Tone.getTransport().seconds = seconds
  }
  get isRewound() {
    return this.clockSeconds === 0
  }

  setAudioPlayer(playerKey: string, player?: NullAudioPlayer) {
    if (player) {
      this._audioPlayers[playerKey] = player
    }
    const newPlayer = this._audioPlayers[playerKey] || this._audioPlayers['default']
    newPlayer.volume = this.volume
    this._player = newPlayer
  }
  set volume(value) {
    this._volume = value
    this.player.volume = value
  }

  get trackDuration() {
    return this.player.trackDuration
  }

  get userInputAudioFile() {
    return this._localAudioFileToUpload
  }
  set userInputAudioFile(localAudioFile: File | undefined) {
    this._localAudioFileToUpload = localAudioFile
    this.audioFileInput.value = ''
  }

  get userInputImageFile() {
    return this._localImageFileToUpload
  }
  set userInputImageFile(localAudioFile: File | undefined) {
    this._localImageFileToUpload = localAudioFile
    this.imageFileInput.value = ''
  }

  get isReady() {
    return this._isReady
  }

  set isReady(isReady) {
    this._isReady = isReady
  }

  get isPlaying() {
    return this._isPlaying
  }

  set isPlaying(isPlaying) {
    this._isPlaying = isPlaying
  }

  get isBlasting() {
    return this.isPlaying && this.isPlayMode
  }

  resume() {
    this.isReady = true
  }

  suspend() {
    this.isReady = false
  }
  updateAnimation() {
    this._gamers.forEach((gamerInfo) => {
      if (gamerInfo.isActive) {
        gamerInfo.gamer.updateAnimation()
      }
    })
  }

  set playerBuffer(newBuffer: Tone.ToneAudioBuffer | null) {
    if (newBuffer) {
      this.setAudioPlayer('tone', new ToneAudioPlayer(newBuffer))
    } else {
      this._player = this._audioPlayers['default'] // TODO: clear tone buffer?
    }
    const trackSeconds = newBuffer ? newBuffer.duration : 0
    this.dispatch(currentPlaySlice.actions.setTrackDuration(trackSeconds))
  }

  // Audio Manager
  start() {
    if (!this.player || this.isPlaying) {
      return // already started
    }
    const startAt = this.clockSeconds
    Tone.getTransport().start()
    this.isPlaying = true
    this.player.start(startAt)
    this._gamers.forEach((gamerInfo) => {
      if (gamerInfo.isActive) {
        gamerInfo.gamer.start()
      }
    })
    this.dispatch(currentPlaySlice.actions.setIsPlaying(true))
    this.dispatch(sessionSlice.actions.setIsShowCountdownClock(false))
  }

  // Audio Manager
  stop() {
    if (!this.player) {
      return
    }
    if (this._isPlaying) {
      Tone.getTransport().pause()
      this.player.stop()
      this.isPlaying = false
      this.dispatch(currentPlaySlice.actions.setStartAtSeconds(this.clockSeconds))
      this._gamers.forEach((gamerInfo) => {
        if (gamerInfo.isActive) {
          gamerInfo.gamer.stop()
        }
      })
      this.updateMatchStatus(true)
    }
    this.dispatch(currentPlaySlice.actions.setIsPlaying(false))
    this.dispatch(sessionSlice.actions.setIsShowCountdownClock(false))
  }
  rewindToStart() {
    if (this.player) {
      // might not have player (e.g. if error loading song) TODO: always have default player?
      this.player.seek(0)
    }
    this.clockSeconds = 0
    this.updateClock()
    this._gamers.forEach((gamerInfo) => {
      if (gamerInfo.isActive) {
        gamerInfo.gamer.rewindToStart()
      }
    })
    this.dispatch(currentPlaySlice.actions.setStartAtSeconds(0))
  }
  rewindTo(seconds: number, part: ModelPart | null = null) {
    if (seconds == null) {
      console.log('null secs') // TODO: why will this ever happen? just default to 0?
      return
    }
    // console.log(`rewindTo ${seconds} [${part.label}]`);
    this.player.seek(seconds)
    this.clockSeconds = seconds
    this.updateClock()
    this._gamers.forEach((gamerInfo) => {
      if (gamerInfo.isActive) {
        gamerInfo.gamer._rewindTo(seconds, part)
      }
    })
    this.dispatch(currentPlaySlice.actions.setStartAtSeconds(seconds))
  }
  clearGamerTimings() {
    this._gamers.forEach((gamerInfo) => {
      if (gamerInfo.isActive) {
        gamerInfo.gamer.clearTiming()
      }
    })
  }
  trackLoaded(trackInfo: TrackInfo) {
    this._gamers.forEach(({ isActive, gamerId }, index) => {
      if (isActive) {
        this.dispatch(
          switchActiveBlaster({
            gamerIndex: index,
            newBlasterSlug: gamerId,
            newlyLoadedTrackInfo: trackInfo,
          })
        )
      }
    })
    this.dispatch(activateNextPane({ pane: Pane.PLAY }))
  }

  clearTimingForGamer(gamerIndex: number, gamerId: string) {
    const gamerInfo = this._gamers[gamerIndex]
    if (gamerInfo) {
      gamerInfo.gamerId = gamerId
      const { isActive, gamer } = gamerInfo
      if (isActive) {
        gamer.clearTiming()
      }
    }
  }
  updateClock() {
    const clockSeconds = this.clockSeconds
    if (clockSeconds > this.trackDuration) {
      this.dispatch(togglePlay())
      this.rewindToStart()
      return
    }
    const clockText = Util.secondsToClock(clockSeconds)
    if (this._clock && this._scrubber) {
      this._clock.value = clockText
      this._scrubber.value = String(clockSeconds)
    }
    const durationText = Util.secondsToClock(this.trackDuration - clockSeconds)
    if (this._duration) {
      this._duration.value = durationText
    }
  }

  updateMatchStatus(isForce = false) {
    if ((this.isPlaying || isForce) && !this.isVizMode) {
      this.dispatch(updateMatchStatus(-1))
    }
  }
}

const defaultPlayer = new TrackMixer()
export default defaultPlayer
