import { createAsyncThunk } from '@reduxjs/toolkit'

import { AppDispatch, RootState } from '../../reducers'
import { selectUserTracksInfo } from '../../selectors/blaster-peer-selectors'
import { selectCurrentUsername } from '../../selectors/session-selectors'
import realtime from '../../services/RealtimeService'
import { TrackInfoMap } from '../../types'

export const syncAllTracks = createAsyncThunk<
  string,
  void,
  { state: RootState; dispatch: AppDispatch }
>('syncAllTracks', async (_, { dispatch, getState }) => {
  const state = getState()
  const currentUsername = selectCurrentUsername(state)
  const userTracks = selectUserTracksInfo(currentUsername)(state)
  const emptyUserTracksMap: { [username: string]: TrackInfoMap } = {}
  let tracksToSync = 0
  const borrowedTracksByOwner = Object.values(userTracks).reduce((acc, trackInfo) => {
    const { slug: trackSlug, owner } = trackInfo
    if (owner !== currentUsername) {
      const ownerMap = acc[owner] || {}
      ownerMap[trackSlug] = trackInfo
      acc[owner] = ownerMap
      tracksToSync++
    }
    return acc
  }, emptyUserTracksMap)
  let syncedTrackCount = 0
  for (const owner of Object.keys(borrowedTracksByOwner)) {
    const ownerTracks = selectUserTracksInfo(owner)(state)
    for (const { slug: trackSlug } of Object.values(borrowedTracksByOwner[owner])) {
      // Object.values(borrowedTracksByOwner[owner]).forEach(async ({ slug: trackSlug }) => {
      const borrowedTrackInfo = ownerTracks[trackSlug]
      if (!borrowedTrackInfo) {
        console.log(`${owner}'s info for track ${trackSlug} missing from state; skipping!`)
        continue
      }
      try {
        await realtime.set(`tracks/${currentUsername}/${trackSlug}`, borrowedTrackInfo)
        console.log(`successfully synced track ${trackSlug} to ${owner}'s info`)
        syncedTrackCount++
      } catch (error) {
        console.log(`failed to update ${trackSlug}`)
      }
    }
  }
  return `synced ${syncedTrackCount} out of ${tracksToSync} borrowed tracks (see dev console for details)`
})
