import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  BLEND_MODES,
  DEFAULT_BACKGROUNDS,
  MINIMUM_VIZ_SWITCH_PART_DURATION,
  VIZ_SWITCH_IN_DELAY_SECS,
  VIZ_SWITCH_OUT_EARLY_SECS,
} from '../constants/constants'
import { AppDispatch, RootState } from '../reducers'
import sessionSlice from '../reducers/sessionSlice'
import blasterPeersSlice from '../reducers/blasterPeersSlice'
import currentPlaySlice from '../reducers/currentPlaySlice'
import { selectCurrentTrackInfo, selectImagePath } from '../selectors/current-play-selectors'
import {
  selectCurrentBackgroundBlendMode,
  selectCurrentBackgroundIndex,
  selectCurrentMatchSlug,
  selectCurrentUsername,
  selectVisualizationInfo,
} from '../selectors/session-selectors'
import { selectScoreVersion } from '../selectors/session-selectors'
import { selectViewSettings } from '../selectors/settings-selectors'
import player from '../services/TrackMixer'
import { Section, VizType } from '../types'
import Gamer from '../services/Gamer'
import { updateLocalTrack } from './authoring/update-local-track'
import { getTrackPoints } from '../util/score-utils'

type SectionInfo = {
  startPart: Section
  prevPart: Section | null
  endPart: Section | null
}
const getEmptySections = (sections: Section[]) => {
  const emptySections = sections.reduce((acc: SectionInfo[], section: Section, index: number) => {
    if (section.lines.length) {
      return acc
    }
    const prevSection = index > 0 ? sections[index - 1] : null
    const nextSection = index + 1 < sections.length ? sections[index + 1] : null
    return [...acc, { prevPart: prevSection, startPart: section, endPart: nextSection }]
  }, [])

  return emptySections
}

export const setDefaultVisualization = createAsyncThunk<
  void,
  { gamer: Gamer; vizSlug: VizType },
  { state: RootState; dispatch: AppDispatch }
>('playAction/setViz', ({ gamer, vizSlug }, { dispatch, getState }) => {
  const { vizBuilder, gamerIndex: playerIndex } = gamer
  dispatch(sessionSlice.actions.setDefaultVisualizationSlug({ playerIndex, vizSlug }))
  vizBuilder.switchVisualization(vizSlug)
  dispatch(scheduleVizSwitches(gamer))
})

const scheduleVizSwitches = createAsyncThunk<
  void,
  Gamer,
  { state: RootState; dispatch: AppDispatch }
>('playAction/scheduleVizSwitches', (gamer, { dispatch, getState }) => {
  const state = getState()
  const scoreVersion = selectScoreVersion(state)
  const vizInfo = selectVisualizationInfo(state)
  const trackInfo = selectCurrentTrackInfo(state)
  const compoundMatchSlug = selectCurrentMatchSlug(state)
  const [matchOwner] = compoundMatchSlug.split('/')
  const username = selectCurrentUsername(state)
  const { vizBuilder, gamerIndex } = gamer
  const { sections, timedWordCount } = vizBuilder
  // console.log('scheduleVizSwitches: ', gamer)
  const { isVizSwitchingEnabled: isTurningOn } = selectViewSettings(gamerIndex)(state)
  const { defaultVizSlug, alternateVizSlug } = vizInfo[gamerIndex]
  // const alternateVizSlug = defaultVizSlug === 'spiral' ? 'wave' : 'spiral'
  const { trackDuration } = player // TODO: get duration from state!
  const switchIn = () => {
    vizBuilder.switchViz({ isSwitchingIn: true, vizSlug: alternateVizSlug })
  }
  const switchOut = () => {
    vizBuilder.switchViz({ isSwitchingIn: false, vizSlug: defaultVizSlug })
  }
  const partsToSchedule: SectionInfo[] = getEmptySections(sections)
  // console.log(`scheduling ${partsToSchedule.length} of ${vizBuilder.sections.length} sections`)
  let lastStart = -1
  let emptyAirtime = 0

  partsToSchedule.forEach(({ prevPart, startPart, endPart }) => {
    // console.log('Scheduling viz for section:', startPart.index, startPart.label)
    let startTime = startPart.referenceTime
    if (startTime === null) {
      console.log('ignoring null section start', startPart)
      return
    }
    if (startTime === 0 && prevPart) {
      if (prevPart.lines.length) {
        const lastline = prevPart.lines[prevPart.lines.length - 1]
        if (lastline.words.length) {
          const lastWord = lastline.words[lastline.words.length - 1]
          if (lastWord.referenceTime) {
            startTime = lastWord.referenceTime
            startPart.referenceTime = startTime
          }
        }
      }
    }
    if (startTime <= lastStart) {
      console.log('ignoring out-of-order empty section start', startPart)
      return
    }
    const referenceEndTime = endPart ? endPart.referenceTime : trackDuration
    const endTime = referenceEndTime !== null ? referenceEndTime : trackDuration
    const partLength = Math.round((endTime - startTime) * 1000) / 1000 // round to milliseconds
    emptyAirtime += partLength
    if (partLength < MINIMUM_VIZ_SWITCH_PART_DURATION) {
      // console.log('ignoring too-short empty section', startPart)
      return
    }
    lastStart = startTime

    if (isTurningOn) {
      // console.log('turning on start viz for', startPart)
      gamer.setPartEvent({
        eventCallback: switchIn,
        part: startPart,
        useReferenceTime: true,
        deltaTime: -VIZ_SWITCH_IN_DELAY_SECS,
      })
    } else {
      // console.log('resetting start viz for', startPart)
      gamer.resetPartTiming(startPart)
    }

    if (endPart) {
      if (isTurningOn) {
        gamer.setPartEvent({
          eventCallback: switchOut,
          part: endPart,
          useReferenceTime: true,
          deltaTime: VIZ_SWITCH_OUT_EARLY_SECS,
        })
      } else {
        gamer.resetPartTiming(endPart)
      }
    }
  })
  vizBuilder.isVizSwitchingScheduled = isTurningOn
  const {
    emptyAirtime: prevEmptyAirtime,
    slug: trackSlug,
    owner,
    duration,
    timedWordCount: prevTimedWordCount,
  } = trackInfo
  const isShouldUpdate = emptyAirtime > 0 && emptyAirtime !== prevEmptyAirtime
  const isShouldUpdateTimedWordCount = prevTimedWordCount !== timedWordCount
  if (isShouldUpdate) {
    console.log(
      `updating emptyAirtime for track ${trackSlug} (${owner}/${matchOwner}): from ${prevEmptyAirtime} to ${emptyAirtime}`
    )
  }
  if (isShouldUpdateTimedWordCount) {
    console.log(
      `updating timedWordCount for track ${trackSlug} (${owner}/${matchOwner}): from ${prevTimedWordCount} to ${timedWordCount}`
    )
  }
  if (isShouldUpdate || isShouldUpdateTimedWordCount) {
    if (owner === username) {
      dispatch(
        updateLocalTrack({
          trackSlug,
          audioData: { emptyAirtime, timedWordCount, isInfoDirty: true },
        })
      )
    } else {
      const newTrackInfo = { ...trackInfo, emptyAirtime, timedWordCount }
      dispatch(
        blasterPeersSlice.actions.updateTrack({
          username: owner,
          trackInfo: newTrackInfo,
        })
      )
      if (matchOwner !== owner) {
        dispatch(
          blasterPeersSlice.actions.updateTrack({
            username: matchOwner,
            trackInfo: newTrackInfo,
          })
        )
      }
      dispatch(currentPlaySlice.actions.setTrackInfo(newTrackInfo))
    }
  }
  const scoreDuration = scoreVersion === 1 ? duration : duration - emptyAirtime
  const { maxPoints: maxScore, pointsPerWord } = getTrackPoints(timedWordCount, scoreDuration)
  console.log(
    `scheduleVizSwitches: maxScore for ${trackSlug} = ${maxScore} (${timedWordCount} * ${pointsPerWord})`
  )
  gamer.pointsPerWord = pointsPerWord
  dispatch(
    currentPlaySlice.actions.updateMaxScore({
      gamerIndex,
      maxScore,
    })
  )
})

export const nextBackgroundImage = createAsyncThunk<
  void,
  boolean | undefined,
  { state: RootState }
>('session/nextBackgroundImage', (isPreferRemoteImage = false, { dispatch, getState }) => {
  const state = getState()
  const currentBackgroundIndex = selectCurrentBackgroundIndex(state)
  const remoteImagePath = selectImagePath(state)
  if (isPreferRemoteImage) {
    dispatch(sessionSlice.actions.setCurrentBackgroundIndex(DEFAULT_BACKGROUNDS.length))
    return
  }
  const numBackgrounds = DEFAULT_BACKGROUNDS.length + (remoteImagePath ? 1 : 0)
  let nextIndex = -1
  while (nextIndex < 0 || nextIndex >= numBackgrounds || nextIndex === currentBackgroundIndex) {
    nextIndex = Math.floor(Math.random() * numBackgrounds)
  }
  dispatch(sessionSlice.actions.setCurrentBackgroundIndex(nextIndex))
})

export const nextBackgroundEffect = createAsyncThunk<void, void, { state: RootState }>(
  'session/nextBackgroundEffect',
  (_, { dispatch, getState }) => {
    const state = getState()
    const currentIndex = selectCurrentBackgroundBlendMode(state)
    const nextIndex = (currentIndex + 1) % BLEND_MODES.length
    dispatch(sessionSlice.actions.setCurrentBackgroundBlendMode(nextIndex))
  }
)

export default scheduleVizSwitches
